export default [
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Program",
    model: null,
    options: [],
    reduce: "id",
    selectText: "name",
    cols: 12,
    clearable: true,
    visible: true,
  },
  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Seller",
    model: null,
    options: [],
    reduce: "id",
    selectText: "user_name",
    cols: 12,
    clearable: true,
    visible: true,
  },

  {
    type: "select",
    margin: true,
    showLabel: true,
    label: "Type",
    model: null,
    options: [
      { id: null, label: "ALL" },
      { id: 0, label: "NEW" },
      { id: 2, label: "CHANGE" },
      { id: 1, label: "ADD" },
    ],
    reduce: "id",
    selectText: "label",
    cols: 12,
    clearable: true,
    visible: true,
  },
];
