<template>
  <div>
    <div class="d-flex">
      <div class="icon-container">
        <feather-icon icon="PieChartIcon" size="24" style="color: #1654cc" />
      </div>
      <div class="pl-2 align-self-center">
        <div>
          <span style="font-weight: 700; font-size: 15px">
            Answer of Month
          </span>
        </div>
      </div>
    </div>
    <div>
      <e-charts ref="line" :options="eCharts.option" autoresize />
    </div>
  </div>
</template>
  <script>
import DashboardService from "@/views/crm/views/dashboard/service/dashboard.service.js";
import ECharts from "vue-echarts";
export default {
  name: "DonutAnswer",
  components: {
    ECharts,
  },
  props: {
    objectData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      eCharts: {
        option: {},
      },
      total: 0,
      chartData: [],
    };
  },
  computed: {
    isDarkSkin() {
      return this.$store.state.appConfig.layout.skin === "dark";
    },
  },
  async created() {
    await this.getDataDashboardLeads();
    this.renderEChart();
  },
  async mounted() {},
  switch: {},
  methods: {
    renderEChart() {
      this.eCharts.option = {
        tooltip: {
          trigger: "item",
          formatter(params) {
            return `${params.name}: ${params.data.value} (${params.percent}%)<br />`;
          },
        },
        legend: {
          top: "70%",
          left: "center",
          itemHeight: 25,
          itemWidth: 25,
          orient: "vertical",
          textStyle: {
            color: this.isDarkSkin ? "#FFFFFF" : `#17171A`,
            rich: {
              a: {
                fontWeight: "bold",
              },
            },
          },
          formatter: (name) => {
            const value = this.chartData.find(
              (item) => item.name == name
            ).value;
            if (value == 0) {
              return "{a|" + name + "}  :   " + value + " ( " + value + "% )";
            }
            const calculatePercent = (value * 100) / this.total;
            return (
              "{a|" +
              name +
              "}  :   " +
              value +
              " ( " +
              calculatePercent.toFixed(2) +
              "% )"
            );
          },
        },
        series: [
          {
            name: "New Leads",
            type: "pie",
            radius: ["55%", "90%"],
            center: ["50%", "35%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 15,
              borderColor: this.isDarkSkin ? "#17171A" : `#FFFFFF`,
              borderWidth: 3,
            },
            label: {
              show: false,
              position: "inside",
              /*formatter: (item) => {    
                                    if(item.percent > 10){
                                      const calculatePercent = (item.value * 100)/this.total ;
                                      return  calculatePercent.toFixed(2) +"%";
                                    }else{
                                      return ''
                                    } 
                                  },*/
              color: this.isDarkSkin ? "#FFFFFF" : `#17171A`,
              //fontSize: 15,
              fontWeight: "bold",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.chartData,
          },
          {
            type: "pie",
            radius: ["0", "10%"],
            center: ["50%", "35%"],
            hoverAnimation: false,
            label: {
              show: true,
              position: "center",
              formatter: "{a|" + this.total + "}\nTotal Leads",
              color: this.isDarkSkin ? "#FFFFFF" : `#17171A`,
              rich: {
                a: {
                  fontSize: 50,
                  fontWeight: "bold",
                  color: this.isDarkSkin ? "#FFFFFF" : `#17171A`,
                },
              },
            },
            itemStyle: {
              color: "transparent",
              borderColor: "transparent",
            },
            data: [{ value: 1 }],
            tooltip: {
              show: false,
            },
          },
        ],
      };
    },
    async getDataDashboardLeads() {
      try {
        const inputDate = new Date(this.objectData.date);
        const utcDate = new Date(
          inputDate.getTime() + inputDate.getTimezoneOffset() * 60000
        );
        const params = {
          type: 1,
          module_origin: this.objectData.originModule,
          year: utcDate.getFullYear(),
          month: utcDate.getMonth() + 1,
          day: utcDate.getDate(),
          type_dashboard: 2,
        };
        const { data } = await DashboardService.getDataDashboardLeads(params);
        this.chartData = [
          {
            value: data[0].answer,
            name: "ANSWER",
            itemStyle: {
              color: "#00DD31",
            },
          },
          {
            value: data[0].non_answer,
            itemStyle: {
              color: "#FF8E00",
            },
            name: "UNANSWER",
          },
        ];
        this.total = data[0].total;
      } catch (error) {
        this.showErrorSwal();
      }
    },
  },
  watch: {
    isDarkSkin: {
      handler: function (val, oldVal) {
        this.eCharts.option.series[0].itemStyle.borderColor = val
          ? "#17171A"
          : "#FFFFFF";
        this.eCharts.option.legend.textStyle.color = val
          ? "#FFFFFF"
          : "#17171A";
        this.eCharts.option.series[1].label.color = val ? "#FFFFFF" : "#17171A";
        this.eCharts.option.series[1].label.rich.a.color = val
          ? "#FFFFFF"
          : "#17171A";
        this.eCharts.option.series[0].label.color = val ? "#FFFFFF" : "#17171A";
      },
      deep: true,
    },
  },
};
</script>
 <style>
.echarts {
  width: 90% !important;
  margin: auto;
}
</style>

<style scoped>
.icon-container {
  background-color: #a7c5ff;
  width: 40px;
  height: 40px;
  padding: 8px 8px 8px 8px;
  border-radius: 5px;
}
</style>