<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <div class="icon-container">
          <feather-icon
            icon="BarChartIcon"
            size="24"
            style="color: #1654CC"
          />
        </div>
        <div class="pl-2 align-self-center" >
          <div v-if="yearData" >
            <span style="font-weight: 700;font-size: 15px;">
              Effectiveness Chart ({{yearData}})
            </span>
          </div>
        </div>
      </div>
      <div class=""> 
        <b-select   
          v-model="optionId"
          value-field="id"
          text-field="name"
          :options="optionsSelections"
          @change="optionChange"
        />
      </div>
    </div>
    <div>
      <e-charts ref="line" :options="eCharts.option" autoresize />
    </div> 
    
  </div>
</template>
  <script>
import DashboardService from "@/views/crm/views/dashboard/service/dashboard.service.js";
import ECharts from "vue-echarts";
export default {
  name: "BarChartAppointmentsCrm",
  components: {
    ECharts,
  },
  props:{
    objectData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dataLeads :{
        answer:0,
        unAnswer:0,
        xAxisData:[],
      }, 
      eCharts: {
        option: {},
      },
      month_names : ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
      yearData:null,
      optionsSelections:[
        {id:1, name:"Answer"},
        {id:2, name:"Potential"},
        {id:3, name:"Number Phone"},
        {id:4, name:"Appointment"}
      ],
      optionId:1, 
      oneName:"Answer",
      twoName:"No Answer",
    };
  },
  computed: {
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin === "dark";
    },
    getOneName(){
      return this.oneName
    },
    getTwoName(){
      return this.twoName
    }
,  },
  async created() {  
    await this.renderEchart(); 
    await this.getAppointmentsDashboardData();
    
  },
  mounted() {},
  methods: {
    async getAppointmentsDashboardData() { 
      try { 
        const inputDate = new Date(this.objectData.date);
        const utcDate = new Date(
          inputDate.getTime() + inputDate.getTimezoneOffset() * 60000
        ); 
        this.yearData =  utcDate.getFullYear() ;
        const params = {
          type: 1,
          module_origin: this.objectData.originModule, 
          year: this.yearData,
          month: utcDate.getMonth() + 1,
          day: utcDate.getDate(),
          type_dashboard: 3,
        };
        const { data } = await DashboardService.getDataDashboardLeads(
          params
        );  
        this.dataChart = data;
        await this.processDataForChart(data);
      } catch (error) {
        this.showErrorSwal();
        console.log(error);
      } 
    },

    async processDataForChart(data) { 
      this.dataLeads.xAxisData = this.month_names;
      this.eCharts.option.xAxis.data = this.dataLeads.xAxisData;

       this.processTotalDataSeries(data);
       this.processTotalDataLegend(data);
    },

    async processTotalDataSeries(data) {
      data = await this.completeMonth(data); 
 

      if(this.optionId==1){
        this.oneName = "Answer";
        this.twoName = "No Answer";
         const typeOne = [...data.map((item) => item.answer)];
        const  typetwo = [...data.map((item) => item.non_answer)];
                this.eCharts.option.series[0].data = typeOne; 
      this.eCharts.option.series[1].data = typetwo;
            this.eCharts.option.series[0].name = this.getOneName;
      this.eCharts.option.series[1].name = this.getTwoName;
      }
      if(this.optionId==2){
        this.oneName = "Potential";
        this.twoName = "No Potential";
         const typeOne = [...data.map((item) => item.potential)];
         const typetwo = [...data.map((item) => item.non_potential)];
                this.eCharts.option.series[0].data = typeOne; 
      this.eCharts.option.series[1].data = typetwo;
            this.eCharts.option.series[0].name = this.getOneName;
      this.eCharts.option.series[1].name = this.getTwoName;
      }
      if(this.optionId==3){
        this.oneName = "Number Phone";
        this.twoName = "No number Phone";
         const typeOne = [...data.map((item) => item.mobile)];
         const typetwo = [...data.map((item) => item.non_mobile)];
                this.eCharts.option.series[0].data = typeOne; 
      this.eCharts.option.series[1].data = typetwo;
            this.eCharts.option.series[0].name = this.getOneName;
      this.eCharts.option.series[1].name = this.getTwoName;
      }
      if(this.optionId==4){
        this.oneName = "Appointment";
        this.twoName = "No Appointment";
        const  typeOne = [...data.map((item) => item.appointment)];
        const  typetwo = [...data.map((item) => item.non_appointment)];
                this.eCharts.option.series[0].data = typeOne; 
      this.eCharts.option.series[1].data = typetwo;
            this.eCharts.option.series[0].name = this.getOneName;
      this.eCharts.option.series[1].name = this.getTwoName;
      }
            this.eCharts.option.legend.data =[
         this.oneName,
          this.twoName
        ];

      
    },

    processTotalDataLegend(data) {

      if(this.optionId==1){
        this.dataLeads.answer = data.reduce(
          (acc, item) => acc + parseInt(item.answer),
          0
        );

        this.dataLeads.unAnswer = data.reduce(
          (acc, item) => acc + parseInt(item.non_answer),
          0
        );
      }

      if(this.optionId==2){
        this.dataLeads.answer = data.reduce(
          (acc, item) => acc + parseInt(item.potential),
          0
        );

        this.dataLeads.unAnswer = data.reduce(
          (acc, item) => acc + parseInt(item.non_potential),
          0
        );
      }

      if(this.optionId==3){
        this.dataLeads.answer = data.reduce(
          (acc, item) => acc + parseInt(item.mobile),
          0
        );

        this.dataLeads.unAnswer = data.reduce(
          (acc, item) => acc + parseInt(item.non_mobile),
          0
        );
      }

      if(this.optionId==4){
        this.dataLeads.answer = data.reduce(
          (acc, item) => acc + parseInt(item.appointment),
          0
        );

        this.dataLeads.unAnswer = data.reduce(
          (acc, item) => acc + parseInt(item.non_appointment),
          0
        );
      }

       
    },
    completeMonth(data){
      const monthsCompleted = [];
      let lastMonth = 1;

      for (let i = lastMonth; i <= 12; i++) {
          const isMonth= data.some(obj=> obj.month == i);
          const objectMonth= data.find(obj=> obj.month == i);
          if(!isMonth){
            monthsCompleted.push({ month: i , answer: 0, non_answer:0 });
          }else{
            monthsCompleted.push(objectMonth);
          }
          
        } 
 
      return monthsCompleted;
    },
    async optionChange(){
      await this.processDataForChart(this.dataChart); 
    },
    async renderEchart(){
          this.eCharts.option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },

      legend: {
        data: [
         this.oneName,
          this.twoName
        ],
        color: ["#0090E7", "#F04242"],
        itemHeight: 30,
        itemWidth: 30,

        textStyle: {
          color: this.isDarkTheme ? "#FFFFFF" : "#17171A",
          margin: 10,
          rich: {
            a: {
              height: 20,
              fontWeight: "bold",
              fontSize: 15,
            },
            b: {
              height: 10,
              fontSize: 12,
            },
          },
        },
        formatter: (name) => {
          let value = 0;
          if (name == this.oneName) {
            value = this.dataLeads.answer;
          } 
          
          if (name == this.twoName) {
            value = this.dataLeads.unAnswer;
          } 
          return ["{b|" + name + ":}", "{b|" + value + "}"].join(" ");
        },
      },

      xAxis: {
        type: "category",
        axisTick: { show: false },
        data: [],
        axisLabel: {
          color: this.isDarkTheme ? "#FFFFFF" : "#616161",
          margin: 10,
          fontSize: 14,
          fontWeight: "500",
        },
      },

      yAxis: {
        type: "value",
        minInterval: 1,
        axisLabel: {
          margin: 10,
          fontSize: 15,
          fontWeight: "500",
          color: this.isDarkTheme ? "#FFFFFF" : "#616161",
        },

        nameTextStyle: {
          color: this.isDarkTheme ? "#FFFFFF" : "#616161",
        },
        axisLine: {
          show: true,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: this.isDarkTheme ? "#FFFFFF" : "#616161",
            type: "dashed",
            width: 1.3,
            interval: 10,
          },
        },
      },

      series: [
        {
          name: this.getOneName ,
          type: "bar",
          barGap: 0,
          color: "#3F7AFA",
          label: {
            show: true,
            position: "top",
            distance: 12,
            align: "center",
            verticalAlign: "middle",
            rotate: 1,
            formatter: "{c}",
            fontSize: 12,
          }, 
          emphasis: {
            focus: "series",
          },
          itemStyle: {
            normal: {
              barBorderRadius: [10, 10, 0, 0],
            },
          },
          data: [],
        },
        {
          name: this.getTwoName,
          type: "bar", 
          barGap: 0,
          color: "#A4E5FF",
          label: {
            show: true,
            position: "top",
            distance: 12,
            align: "center",
            verticalAlign: "middle",
            rotate: 1,
            formatter: "{c}",
            fontSize: 12,
          },
          emphasis: {
            focus: "series",
          },
          itemStyle: {
            normal: {
              barBorderRadius: [10, 10, 0, 0],
            },
          },
          data: [],
        },
      ],
    };
    }
  },
  watch: {
    isDarkSkin: {
      handler: function (val, oldVal) {
        this.eCharts.option.legend.textStyle.color = val
          ? "#FFFFFF"
          : "#17171A";
        this.eCharts.option.xAxis.axisLabel.color = val ? "#FFFFFF" : "#616161";
        this.eCharts.option.yAxis.axisLabel.color = val ? "#FFFFFF" : "#616161";
        this.eCharts.option.yAxis.splitLine.lineStyle.color = val
          ? "#FFFFFF"
          : "#616161";
      },
      deep: true,
    },
  },
};
</script>
 <style>
.echarts {
  width: 90% !important;
  margin: auto;
}
</style>