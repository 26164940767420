<template>
  <div class="card-boss h-100">
    <div class="w-100 h-100">
      <div class="parent_card h-100">
        <div
          class="sales-card-wrapper cursor-pointer"
        >
          <div>
            <div
              class="d-flex justify-content-between inner-card-title-container"
            >
              <div>
                <span class="inner-card-title">Done</span>
              </div>
            </div>
            <div class="inner-line-divider" />
            <div class="e-indicators">
              <div
                class="saler-card-wrapper__child"
                :class="{
                  'gray-card': !activeDailyDone && !isDarkSkin,
                  'gray-card-dark': !activeDailyDone && isDarkSkin,
                  'active-blue-card': activeDailyDone && !isDarkSkin,
                  'active-blue-card-dark': activeDailyDone && isDarkSkin,
                }"
                @click="openModalSalesDetails('daily_done')"
              >
                <div class="indicator-number">
                  {{
                    doneSalesIndicators.daily_sales > 99
                      ? "+99"
                      : doneSalesIndicators.daily_sales
                  }}
                </div>
                <div class="indicator-legend">
                  <span>Total</span>
                  <br />
                  <span>today</span>
                </div>
              </div>
              <div
                class="saler-card-wrapper__child"
                :class="{
                  'gray-card': !activeMonthlyDone && !isDarkSkin,
                  'gray-card-dark': !activeMonthlyDone && isDarkSkin,
                  'active-blue-card': activeMonthlyDone && !isDarkSkin,
                  'active-blue-card-dark': activeMonthlyDone && isDarkSkin,
                }"
                @click="openModalSalesDetails('monthly_done')"
              >
                <div class="indicator-number">
                  {{
                    doneSalesIndicators.monthly_sales > 99
                      ? "+99"
                      : doneSalesIndicators.monthly_sales
                  }}
                </div>
                <div class="indicator-legend">
                  <span>Total</span>
                  <br />
                  <span>this month</span>
                </div>
              </div>
            </div>
            <div class="margin-ten-top" />
            <div class="list-container">
              <div class="list-container__child">
                <div class="list-container__child-text">
                  <ul>
                    <li>Fee:</li>
                  </ul>
                </div>
                <div
                  v-if="activeMonthlyDone"
                  class="list-container__child-text"
                >
                  ${{
                    doneSalesIndicators.monthly_sales_fee_amount | currencyZero
                  }}
                </div>
                <div v-if="activeDailyDone" class="list-container__child-text">
                  ${{
                    doneSalesIndicators.daily_sales_fee_amount | currencyZero
                  }}
                </div>
              </div>
              <div class="inner-line-divider-list" />
              <div class="list-container__child">
                <div class="list-container__child-text">
                  <ul>
                    <li>IP:</li>
                  </ul>
                </div>
                <div
                  v-if="activeMonthlyDone"
                  class="list-container__child-text"
                >
                  ${{
                    doneSalesIndicators.monthly_sales_ip_amount | currencyZero
                  }}
                </div>
                <div v-if="activeDailyDone" class="list-container__child-text">
                  ${{
                    doneSalesIndicators.daily_sales_ip_amount | currencyZero
                  }}
                </div>
              </div>
              <div class="inner-line-divider-list" />
              <div class="margin-ten-bottom" />
            </div>
          </div>
          <div class="show-details-sales-container">
            <b-button
              v-b-tooltip.hover.bottom="`View Details in Sales Done`"
              variant="primary"
              class="show-details-sales"
              @click="$emit('details', currentDoneType)"
            >
              Show details
            </b-button>
          </div>
        </div>

        <div
          class="sales-card-wrapper cursor-pointer"
        >
          <div>
            <div class="d-flex justify-content-between">
              <div>
                <span class="inner-card-title">Pending </span>
              </div>
            </div>
            <div class="inner-line-divider" />
            <div class="e-indicators">
              <div
                class="saler-card-wrapper__child"
                :class="{
                  'gray-card-danger': !activeDailyPending && !isDarkSkin,
                  'gray-card-dark-danger': !activeDailyPending && isDarkSkin,
                  'active-danger-card': activeDailyPending && !isDarkSkin,
                  'active-danger-card-dark': activeDailyPending && isDarkSkin,
                }"
                @click="openModalSalesDetails('daily_pending')"
              >
                <div class="indicator-number">
                  {{
                    pendingSalesIndicators.daily_sales > 99
                      ? "+99"
                      : pendingSalesIndicators.daily_sales
                  }}
                </div>
                <div class="indicator-legend">
                  <span>Total</span>
                  <br />
                  <span>today</span>
                </div>
              </div>
              <div
                class="saler-card-wrapper__child"
                :class="{
                  'gray-card-danger': !activeMonthlyPending && !isDarkSkin,
                  'gray-card-dark-danger': !activeMonthlyPending && isDarkSkin,
                  'active-danger-card': activeMonthlyPending && !isDarkSkin,
                  'active-danger-card-dark': activeMonthlyPending && isDarkSkin,
                }"
                @click="openModalSalesDetails('monthly_pending')"
              >
                <div class="indicator-number">
                  {{
                    pendingSalesIndicators.monthly_sales > 99
                      ? "+99"
                      : pendingSalesIndicators.monthly_sales
                  }}
                </div>
                <div class="indicator-legend">
                  <span>Total</span>
                  <br />
                  <span>this month</span>
                </div>
              </div>
            </div>
            <div class="margin-ten-top" />
            <div class="list-container">
              <div class="list-container__child">
                <div class="list-container__child-text">
                  <ul>
                    <li>Fee:</li>
                  </ul>
                </div>
                <div
                  v-if="activeMonthlyPending"
                  class="list-container__child-text text-warning"
                >
                  ${{
                    pendingSalesIndicators.monthly_sales_fee_amount
                      | currencyZero
                  }}
                </div>
                <div
                  v-if="activeDailyPending"
                  class="list-container__child-text text-warning"
                >
                  ${{
                    pendingSalesIndicators.daily_sales_fee_amount | currencyZero
                  }}
                </div>
              </div>
              <div class="inner-line-divider-list" />
              <div class="list-container__child">
                <div class="list-container__child-text">
                  <ul>
                    <li>IP:</li>
                  </ul>
                </div>
                <div
                  v-if="activeMonthlyPending"
                  class="list-container__child-text text-warning"
                >
                  ${{
                    pendingSalesIndicators.monthly_sales_ip_amount
                      | currencyZero
                  }}
                </div>
                <div
                  v-if="activeDailyPending"
                  class="list-container__child-text text-warning"
                >
                  ${{
                    pendingSalesIndicators.daily_sales_ip_amount | currencyZero
                  }}
                </div>
              </div>
              <div class="inner-line-divider-list" />
              <div class="margin-ten-bottom" />
            </div>
          </div>
          <div class="show-details-sales-container">
            <b-button
              v-b-tooltip.hover.bottom="`View Details in Sales Pending`"
              variant="primary"
              class="show-details-sales"
              @click="$emit('details', currentPendingType)"
            >
              Show details
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    pendingSalesIndicators: {
      type: Object,
      default: () => {},
    },
    doneSalesIndicators: {
      type: Object,
      default: () => {},
    },
    innerCardSelections: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeDailyPending: this.innerCardSelections.active_daily_pending,
      activeMonthlyPending: this.innerCardSelections.active_monthly_pending,
      activeDailyDone: this.innerCardSelections.active_daily_done,
      activeMonthlyDone: this.innerCardSelections.active_monthly_done,
      currentDoneType: 'daily_done',
      currentPendingType: 'daily_pending',
    };
  },
  computed: {},
  beforeDestroy() {
    this.$emit('keepInnerCardTimeRateSelectionSync', {
      active_daily_done: this.activeDailyDone,
      active_monthly_done: this.activeMonthlyDone,
      active_daily_pending: this.activeDailyPending,
      active_monthly_pending: this.activeMonthlyPending,
    });
  },
  created() {
    this.openModalSalesDetails(this.activeDailyDone ? 'daily_done' : 'monthly_done');
    this.openModalSalesDetails(this.activeDailyPending ? 'daily_pending' : 'monthly_pending');
  },
  mounted() {},
  methods: {
    openModalSalesDetails(type) {
      const currentActiveDailyDone = this.activeDailyDone;
      const currentActiveMonthlyDone = this.activeMonthlyDone;
      const currentActiveDailyPending = this.activeDailyPending;
      const currentActiveMonthlyPending = this.activeMonthlyPending;
      this.resetActiveCards();
      switch (type) {
        case "daily_done":
          this.currentDoneType = "daily_done";
          this.activeDailyDone = true;
          this.activeDailyPending = currentActiveDailyPending;
          this.activeMonthlyPending = currentActiveMonthlyPending;
          break;
        case "monthly_done":
          this.currentDoneType = "monthly_done";
          this.activeMonthlyDone = true;
          this.activeDailyPending = currentActiveDailyPending;
          this.activeMonthlyPending = currentActiveMonthlyPending;
          break;
        case "daily_pending":
          this.currentPendingType = "daily_pending";
          this.activeDailyPending = true;
          this.activeDailyDone = currentActiveDailyDone;
          this.activeMonthlyDone = currentActiveMonthlyDone;
          break;
        case "monthly_pending":
          this.currentPendingType = "monthly_pending";
          this.activeMonthlyPending = true;
          this.activeDailyDone = currentActiveDailyDone;
          this.activeMonthlyDone = currentActiveMonthlyDone;
          break;
        default:
          break;
      }
      this.$emit("mark_detail_type", type);
    },
    resetActiveCards() {
      this.activeDailyPending = false;
      this.activeMonthlyPending = false;
      this.activeDailyDone = false;
      this.activeMonthlyDone = false;
    },
  },
};
</script>
<style lang="scss">
.saler-card-wrapper__child {
  margin-top: 10px;
}
.parent-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.3rem;
}
.parent-title-container__indicator {
  font-weight: 900;
}
.inner-dot {
  width: 0.3rem;
  height: 0.3rem;
  background: #5e5858;
  border-radius: 100%;
  margin-right: 0.5rem;
}
.parent_card {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}
.card-boss {
  width: 100%;
  margin: 0 auto 0 auto;
}
.sales-card-wrapper {
  box-shadow: 0px 1px 4px 0px rgb(57, 67, 76, 0.24) !important;
  border-radius: 0.2rem;
  width: 50%;
  padding: 1.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.active-blue-card {
  background: #bfd3fc !important;
}

.active-danger-card {
  background: #fae2e0 !important;
}

.gray-card,
.gray-card-danger {
  background: #f4f4f4 !important;
  transition: background-color 0.5s ease;
  color: #999999;
}
.gray-card:hover {
  background: #bfd3fc !important;
  color: unset;
}

.gray-card-danger:hover {
  background: #f4f4f4 !important;
  color: unset;
}

.gray-card-dark {
  background: #1c1c1e !important;
}
.saler-card-wrapper__child {
  cursor: pointer;
  padding: 1rem 0;
  width: 50%;
  border-radius: 0.4rem;
}
.gray-card-dark:hover {
  background: #0090e7 !important;
}
.e-indicators {
  display: flex;
  justify-content: space-between;
  gap: 0.6rem;
  margin-top: 10px;
}
.indicator-number {
  font-weight: 900;
  text-align: center;
  font-size: 2rem;
}
.indicator-legend {
  font-weight: 500;
  text-align: center;
  font-size: 1rem;
}
.line-divider {
  width: 100%;
  border-bottom: 1px solid #efefef;
  margin: 20px 0 30px 0;
}
.inner-line-divider {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #efefef;
  margin: 10px 0;
}
.inner-line-divider-list {
  width: 100%;
  border-bottom: 1px solid #eeebeb;
  margin: 5px 0;
}
.inner-card-title-container {
  margin-top: 5px;
  margin-bottom: 5px;
}
.inner-card-title {
  font-weight: 600;
  font-size: 1.2rem;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 5px;
}
.list-container__child {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 1.2rem;
  margin-top: 5px;
}
.list-container ul,
.list-container li {
  padding: 0 0 0 0;
  margin: 0 0 0 0.8rem;
}
.list-container__child-text {
  font-size: 1.1rem;
  margin-top: 1rem;
}

.margin-ten-top {
  margin-top: 10px;
}
.margin-ten-bottom {
  width: 100%;
  height: 10px;
}

.dark-layout {
  .inner-line-divider-list,
  .inner-line-divider,
  .line-divider {
    border-bottom: 1px solid #2e2e2e;
  }
  .sales-card-wrapper {
    background: #1c1c1e;
    box-shadow: 0px 1px 8px 1px rgb(0 0 0 / 24%) !important;
  }
  .saler-card-wrapper__child {
    background: #2d2d2d !important;
    color: #fff;
  }
  .active-blue-card-dark {
    background: #0090e7 !important;
  }
  .gray-card-dark:hover {
    background: #0090e7 !important;
  }

  .active-danger-card-dark {
    background: #ff854d !important;
  }
  .gray-card-dark-danger:hover {
    background: #ff854d !important;
  }
}

.show-details-sales-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-details-sales {
  padding: 5px 10px;
}
</style>
