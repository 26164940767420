import { render, staticRenderFns } from "./SidebarCrm.vue?vue&type=template&id=1938f0fb"
import script from "./SidebarCrm.vue?vue&type=script&lang=js"
export * from "./SidebarCrm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports