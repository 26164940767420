<template>
  <b-card class="fee-sales-container custom-dasdasjd">
    <div>
      <div class="card-container">
        <div
          class="header-echart"
        >
          <div class="icon-container">
            <feather-icon
              icon="PieChartIcon"
              size="24"
              style="color: #1654CC"
            />
          </div>
          <div style="padding-left: 15px; position: relative; margin-bottom: 10px;">
            <div style="position: absolute; top: -5px;">
              <span class="card-title">
                Sales
              </span><br>
              <span class="card-title">
                Comparison
              </span>
            </div>
          </div>
        </div>
        <div style="height: 290px !important;">
          <e-charts
            ref="line"
            :options="chartOptions"
            autoresize
          />
        </div>
      </div>
    </div>
  </b-card>
</template>
<script>
import ECharts from 'vue-echarts';

export default {
  components: {
    ECharts,
  },
  props: {
    pendingSalesIndicators: {
    },
    doneSalesIndicators: {
    },
  },
  computed: {
    borderColor() {
      return this.isDarkSkin ? '#000000' : '#ffffff';
    },
    chartOptions() {
      return {
        title: {
          show: false,
          text: 'Título del Gráfico',
          textStyle: {
            fontSize: 18,
            fontWeight: 'bold',
          },
          left: 'center',
          top: 10,
        },
        tooltip: {
          trigger: 'item',
          formatter(params) {
            return `
              ${params.name}: ${params.data.value} (${params.percent}%)<br />`;
          },
        },
        legend: {
          data: ['Done', 'Pending'],
          itemHeight: 30,
          itemWidth: 30,
          bottom: '5%',
          left: 'center',
          color: ['#00DD31', '#FF8E00'],
          formatter: name => {
            let value = 0;
            if (name === 'Pending') {
              value = this.pendingSalesIndicators;
            }
            if (name === 'Done') {
              value = this.doneSalesIndicators;
            }
            const percentage = this.totalAmount === 0 ? '0.00' : ((value / this.totalAmount) * 100).toFixed(2);
            return [`${name}:`, `${value} (${percentage}%)`].join(' ');
          },
          textStyle: {
            color: this.isDarkSkin ? '#FFFFFF' : '#17171A',
            margin: 10,
            rich: {
              a: {
                height: 20,
                fontWeight: 'bold',
                fontSize: 15,
              },
              b: {
                height: 10,
                fontSize: 12,
              },
            },
          },
        },
        series: [
          {
            name: 'Indicators',
            type: 'pie',
            top: '-20%',
            bottom: '-5%',
            radius: ['55%', '90%'],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 20,
              borderColor: '#fff',
              borderWidth: 2,
            },
            label: {
              show: true,
              position: 'center',
              fontSize: 10,
              fontWeight: 'bold',
              formatter: `{a|${this.totalAmount}}`,
              rich: {
                a: {
                  fontSize: 44,
                  fontWeight: 'bold',
                  color: this.isDarkSkin ? '#FFFFFF' : '#17171A',
                },
              },
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: this.pendingSalesIndicators,
                name: 'Pending',
                itemStyle: {
                  color: '#FF8E00',
                  borderColor: this.borderColor,
                },
              },
              {
                value: this.doneSalesIndicators,
                name: 'Done',
                itemStyle: {
                  color: '#00DD31',
                  borderColor: this.borderColor,
                },
              },
            ],
          },
        ],
      };
    },
    totalAmount() {
      return this.pendingSalesIndicators + this.doneSalesIndicators;
    },
  },
  created() {
  },
  methods: {},
};
</script>
<style scoped>
.fee-sales-container .icon-container {
  background-color: #A7C5FF;
  width: 40px;
  height: 40px;
  padding: 8px 8px 8px 8px;
  border-radius: 5px;
}
.fee-sales-container .card-title {
  margin-top: 5px;
  text-align: left;
  font-weight: 600;
}

.card-title h2 {
  font-size: 18px;
  font-weight: bold;
}
</style>
<style>
.fee-sales-container {
  border-bottom: none !important;
}
.e-chart-styles-v {
  height: 200px !important;
}
.header-echart {
  display: flex;
  justify-content: flex-start;
  align-items: start;
  padding-left: 15px;
}
.fee-sales-container canvas {
  top: -10px !important;
}
.custom-dasdasjd {
  padding: 0px !important;
  height: 100%;
}
</style>
