<template>
  <div class="card-leads-dashboard-crm d-flex justify-content-center">
    <b-card
      body-class="card-leads-body"
      class="card-new-leads cursor-pointer"
      @click="$emit('click')"
    >
      <div>
        <div class="d-flex justify-content-start">
          <span class="card-title mb-0"><b>New Leads</b></span>
        </div>
        <hr />
        <div class="d-flex py-1">
          <div
            class="d-flex text-center"
            style="padding-right: 5px; border-right: 1px solid black"
          >
            <div
              class="w-100 d-flex flex-column"
              :class="isTodayLeadsSelected ? 'card-active' : 'card-inactive'"
              @click="$emit('changeData', 1, typeModuleSelected)"
              style="margin-right: 2px"
            >
              <span class="number-leads">{{ newLeads.countToday }}</span>
              <span class="card-font">Total leads today</span>
            </div>
            <div
              class="w-100 d-flex flex-column"
              :class="isTodayLeadsSelected ? 'card-inactive' : 'card-active'"
              @click="$emit('changeData', 2, typeModuleSelected)"
              style="margin-left: 2px"
            >
              <span class="number-leads">{{ newLeads.countMonth }}</span>
              <span class="card-font">Total leads this month</span>
            </div>
          </div>
          <div
            class="d-flex flex-column justify-content-between dark-font"
            style="padding-left: 5px; gap: 0.5rem"
          >
            <div
              class="w-100 d-flex justify-content-between card-programs align-items-center"
              :class="typeModuleSelected == 1 ? 'card-active' : 'card-inactive'"
              @click="selectedTypeModule(1)"
            >
              <span>MM:</span>
              <span class="card-font">{{ newLeads.quantityMm }}</span>
            </div>
            <div
              class="w-100 d-flex justify-content-between card-active card-programs align-items-center"
              :class="typeModuleSelected == 2 ? 'card-active' : 'card-inactive'"
              @click="selectedTypeModule(2)"
            >
              <span>CRM:</span>
              <span class="card-font">{{ newLeads.quantityCrm }}</span>
            </div>
            <div
              class="w-100 d-flex justify-content-between card-active card-programs align-items-center"
              :class="typeModuleSelected == 3 ? 'card-active' : 'card-inactive'"
              @click="selectedTypeModule(3)"
            >
              <span>Other programs:</span>
              <span class="card-font">{{ newLeads.quantityOthers }}</span>
            </div>
          </div>
        </div>
        <div class="card-font" v-if="typeModuleSelected == 1">
          <div class="d-flex">
            <div style="width: 70%">
              <span class="px-1">·</span><span>Answer</span>
            </div>
            <div class="d-flex justify-content-around" style="width: 30%">
              <div
                class="d-flex"
                :class="newLeads.answered != 0 ? 'open-modal-type' : ''"
                @click="
                  newLeads.answered != 0 ? viewDetailsForAnswer(1, 1) : true
                "
              >
                <feather-icon class="text-success" size="18" icon="CheckIcon" />
                <span class="data-number">{{ newLeads.answered }}</span>
              </div>
              <div
                class="d-flex"
                :class="newLeads.unAnswered != 0 ? 'open-modal-type' : ''"
                @click="
                  newLeads.unAnswered != 0 ? viewDetailsForAnswer(1, 0) : true
                "
              >
                <feather-icon class="text-danger" size="18" icon="XIcon" />
                <span class="data-number">{{ newLeads.unAnswered }}</span>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div class="card-font" v-if="typeModuleSelected == 1">
          <div class="d-flex">
            <div style="width: 70%">
              <span class="px-1">·</span><span>Potential</span>
            </div>
            <div class="d-flex justify-content-around" style="width: 30%">
              <div
                class="d-flex"
                :class="newLeads.potential != 0 ? 'open-modal-type' : ''"
                @click="
                  newLeads.potential != 0 ? viewDetailsForPotential(1, 1) : true
                "
              >
                <feather-icon class="text-success" size="18" icon="CheckIcon" />
                <span class="data-number">{{ newLeads.potential }}</span>
              </div>
              <div
                class="d-flex"
                :class="newLeads.noPotential != 0 ? 'open-modal-type' : ''"
                @click="
                  newLeads.noPotential != 0
                    ? viewDetailsForPotential(1, 0)
                    : true
                "
              >
                <feather-icon class="text-danger" size="18" icon="XIcon" />
                <span class="data-number">{{ newLeads.noPotential }}</span>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div class="card-font">
          <div class="d-flex">
            <div style="width: 70%">
              <span class="px-1">·</span><span>Number Phone</span>
            </div>
            <div class="d-flex justify-content-around" style="width: 30%">
              <div
                class="d-flex"
                :class="newLeads.numberPhone != 0 ? 'open-modal-type' : ''"
                @click="
                  newLeads.numberPhone != 0 ? viewDetailsForNumber(1, 1) : true
                "
              >
                <feather-icon class="text-success" size="18" icon="CheckIcon" />
                <span class="data-number">{{ newLeads.numberPhone }}</span>
              </div>
              <div
                class="d-flex"
                :class="newLeads.noNumberPhone != 0 ? 'open-modal-type' : ''"
                @click="
                  newLeads.noNumberPhone != 0
                    ? viewDetailsForNumber(1, 0)
                    : true
                "
              >
                <feather-icon class="text-danger" size="18" icon="XIcon" />
                <span class="data-number">{{ newLeads.noNumberPhone }}</span>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div class="card-font">
          <div class="d-flex">
            <div style="width: 70%">
              <span class="px-1">·</span><span>Appointment</span>
            </div>
            <div class="d-flex justify-content-around" style="width: 30%">
              <div
                class="d-flex"
                :class="newLeads.appointment != 0 ? 'open-modal-type' : ''"
                @click="
                  newLeads.appointment != 0
                    ? viewDetailsForAppointment(1, 1)
                    : true
                "
              >
                <feather-icon class="text-success" size="18" icon="CheckIcon" />
                <span class="data-number">{{ newLeads.appointment }}</span>
              </div>
              <div
                class="d-flex"
                :class="newLeads.noAppointment != 0 ? 'open-modal-type' : ''"
                @click="
                  newLeads.noAppointment != 0
                    ? viewDetailsForAppointment(1, 0)
                    : true
                "
              >
                <feather-icon class="text-danger" size="18" icon="XIcon" />
                <span class="data-number">{{ newLeads.noAppointment }}</span>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>

      <div class="show-details-container text-center">
        <b-button
          v-b-tooltip.hover.bottom="`View Details in Appointments`"
          variant="primary"
          class="show-details-button"
          @click="viewDetails(1)"
        >
          Show details
        </b-button>
      </div>
    </b-card>
    <b-card
      body-class="card-recovery-body"
      class="card-recovery cursor-pointer"
      @click="$emit('click')"
    >
      <div>
        <div class="d-flex justify-content-start">
          <span class="card-title mb-0"><b>Recovery</b></span>
        </div>
        <hr />
        <div class="d-flex py-1">
          <div class="w-100 d-flex text-center">
            <div
              class="w-100 d-flex flex-column"
              :class="isTodayRecoverySelected ? 'card-active' : 'card-inactive'"
              @click="$emit('changeData', 3, typeModuleSelected)"
              style="margin-right: 2px"
            >
              <span class="number-leads">{{ recoveryLeads.countToday }}</span>
              <span class="card-font">Total leads recovery today</span>
            </div>
            <div
              class="w-100 d-flex flex-column"
              :class="isTodayRecoverySelected ? 'card-inactive' : 'card-active'"
              @click="$emit('changeData', 4, typeModuleSelected)"
              style="margin-left: 2px"
            >
              <span class="number-leads">{{ recoveryLeads.countMonth }}</span>
              <span class="card-font">Total leads this month</span>
            </div>
          </div>
        </div>
        <div class="card-font">
          <div class="d-flex">
            <div style="width: 70%">
              <span class="px-1">·</span><span>Answer</span>
            </div>
            <div class="d-flex justify-content-around" style="width: 30%">
              <div
                class="d-flex"
                :class="recoveryLeads.answered != 0 ? 'open-modal-type' : ''"
                @click="
                  recoveryLeads.answered != 0
                    ? viewDetailsForAnswer(2, 1)
                    : true
                "
              >
                <feather-icon class="text-success" size="18" icon="CheckIcon" />
                <span class="data-number">{{ recoveryLeads.answered }}</span>
              </div>
              <div
                class="d-flex"
                :class="recoveryLeads.unAnswered != 0 ? 'open-modal-type' : ''"
                @click="
                  recoveryLeads.unAnswered != 0
                    ? viewDetailsForAnswer(2, 0)
                    : true
                "
              >
                <feather-icon class="text-danger" size="18" icon="XIcon" />
                <span class="data-number">{{ recoveryLeads.unAnswered }}</span>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div class="card-font">
          <div class="d-flex">
            <div style="width: 70%">
              <span class="px-1">·</span><span>Potential</span>
            </div>
            <div class="d-flex justify-content-around" style="width: 30%">
              <div
                class="d-flex"
                :class="recoveryLeads.potential != 0 ? 'open-modal-type' : ''"
                @click="
                  recoveryLeads.potential != 0
                    ? viewDetailsForPotential(2, 1)
                    : true
                "
              >
                <feather-icon class="text-success" size="18" icon="CheckIcon" />
                <span class="data-number">{{ recoveryLeads.potential }}</span>
              </div>
              <div
                class="d-flex"
                :class="recoveryLeads.noPotential != 0 ? 'open-modal-type' : ''"
                @click="
                  recoveryLeads.noPotential != 0
                    ? viewDetailsForPotential(2, 0)
                    : true
                "
              >
                <feather-icon class="text-danger" size="18" icon="XIcon" />
                <span class="data-number">{{ recoveryLeads.noPotential }}</span>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div class="card-font">
          <div class="d-flex">
            <div style="width: 70%">
              <span class="px-1">·</span><span>Number Phone</span>
            </div>
            <div class="d-flex justify-content-around" style="width: 30%">
              <div
                class="d-flex"
                :class="recoveryLeads.numberPhone != 0 ? 'open-modal-type' : ''"
                @click="
                  recoveryLeads.numberPhone != 0
                    ? viewDetailsForNumber(2, 1)
                    : true
                "
              >
                <feather-icon class="text-success" size="18" icon="CheckIcon" />
                <span class="data-number">{{ recoveryLeads.numberPhone }}</span>
              </div>
              <div
                class="d-flex"
                :class="
                  recoveryLeads.noNumberPhone != 0 ? 'open-modal-type' : ''
                "
                @click="
                  recoveryLeads.noNumberPhone != 0
                    ? viewDetailsForNumber(2, 0)
                    : true
                "
              >
                <feather-icon class="text-danger" size="18" icon="XIcon" />
                <span class="data-number">{{ recoveryLeads.noNumberPhone }}</span>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div class="card-font">
          <div class="d-flex">
            <div style="width: 70%">
              <span class="px-1">·</span><span>Appointment</span>
            </div>
            <div class="d-flex justify-content-around" style="width: 30%">
              <div
                class="d-flex"
                :class="recoveryLeads.appointment != 0 ? 'open-modal-type' : ''"
                @click="
                  recoveryLeads.appointment != 0
                    ? viewDetailsForAppointment(2, 1)
                    : true
                "
              >
                <feather-icon class="text-success" size="18" icon="CheckIcon" />
                <span class="data-number">{{ recoveryLeads.appointment }}</span>
              </div>
              <div
                class="d-flex"
                :class="
                  recoveryLeads.noAppointment != 0 ? 'open-modal-type' : ''
                "
                @click="
                  recoveryLeads.noAppointment != 0
                    ? viewDetailsForAppointment(2, 0)
                    : true
                "
              >
                <feather-icon class="text-danger" size="18" icon="XIcon" />
                <span class="data-number">{{ recoveryLeads.noAppointment }}</span>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>

      <div class="show-details-container text-center">
        <b-button
          v-b-tooltip.hover.bottom="`View Details in Appointments`"
          variant="primary"
          class="show-details-button"
          @click="viewDetails(2)"
        >
          Show details
        </b-button>
      </div>
    </b-card>
    <modal-leads-details
      v-if="showModalLeadsDetails"
      :object-data="objectData"
      :type="typeDetails"
      :type-date="
        typeDetails == 1
          ? isTodayLeadsSelected
            ? 1
            : 2
          : isTodayRecoverySelected
          ? 1
          : 2
      "
      :type-answer="typeAnswer"
      :type-potential="typePotential"
      :type-number="typeNumberPhone"
      :type-appointment="typeAppointment"
      :type-module="propTypeModule"
      :type-show="typeShow"
      @hidden="showModalLeadsDetails = false"
    />
  </div>
</template>
<script>
import DashboardService from "@/views/crm/views/dashboard/service/dashboard.service";
import ModalLeadsDetails from "@/views/crm/views/dashboard/components/leads/modal/modalLeadsGrid.vue";
export default {
  components: {
    ModalLeadsDetails,
  },
  props: {
    objectData: {
      type: Object,
      default: () => {},
    },
    isTodayLeadsSelected: {
      type: Boolean,
      default: true,
    },
    isTodayRecoverySelected: {
      type: Boolean,
      default: true,
    },
    typeModuleSelected: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      newLeads: {
        quantity: 0,
        unAnswered: 0,
        answered: 0,
        potential: 0,
        noPotential: 0,
        numberPhone: 0,
        noNumberPhone: 0,
        appointment: 0,
        noAppointment: 0,
        quantityMm: 0,
        quantityCrm: 0,
        quantityOthers: 0,
        countToday: 0,
        countMonth: 0,
      },
      recoveryLeads: {
        quantity: 0,
        unAnswered: 0,
        answered: 0,
        potential: 0,
        noPotential: 0,
        numberPhone: 0,
        noNumberPhone: 0,
        appointment: 0,
        noAppointment: 0,
        countToday: 0,
        countMonth: 0,
      },
      showModalLeadsDetails: false,
      typeDetails: 1,
      typeAnswer: null,
      typePotential: null,
      typeNumberPhone: null,
      typeAppointment: null,
      typeShow: null,
      propTypeModule: null,
    };
  },
  computed: {},
  async created() {},
  async mounted() {
    await this.getLeadsDashboardData(
      1,
      this.isTodayLeadsSelected ? 1 : 2,
      this.typeModuleSelected
    );
    await this.getLeadsDashboardData(
      2,
      this.isTodayRecoverySelected ? 1 : 2,
      this.typeModuleSelected
    );
  },
  methods: {
    async getLeadsDashboardData(type, typeDate, typeModule) {
      try {
        const inputDate = new Date(this.objectData.date);
        const utcDate = new Date(
          inputDate.getTime() + inputDate.getTimezoneOffset() * 60000
        );

        const params = {
          type,
          module_origin: this.objectData.originModule,
          year: utcDate.getFullYear(),
          month: utcDate.getMonth() + 1,
          day: utcDate.getDate(),
          type_date: typeDate,
          type_module_selected: type == 1 ? typeModule : 1,
        };
        const { data } = await DashboardService.getLeadsDashboardData(params);
        if (data.length > 0) {
          if (type == 1) {
            this.newLeads.quantity = data[0].leads_number;
            this.newLeads.unAnswered = data[0].dialogue_no;
            this.newLeads.answered = data[0].dialogue_yes;
            this.newLeads.potential = data[0].potential_yes;
            this.newLeads.noPotential = data[0].potential_no;
            this.newLeads.numberPhone = data[0].mobile_yes;
            this.newLeads.noNumberPhone = data[0].mobile_no;
            this.newLeads.appointment = data[0].appointment_yes;
            this.newLeads.noAppointment = data[0].appointment_no;
            this.newLeads.quantityMm = data[0].count_mm;
            this.newLeads.quantityCrm = data[0].count_crm;
            this.newLeads.quantityOthers = data[0].count_others;
            this.newLeads.countToday = data[0].count_today;
            this.newLeads.countMonth = data[0].count_month;
          } else {
            this.recoveryLeads.quantity = data[0].leads_number;
            this.recoveryLeads.unAnswered = data[0].dialogue_no;
            this.recoveryLeads.answered = data[0].dialogue_yes;
            this.recoveryLeads.potential = data[0].potential_yes;
            this.recoveryLeads.noPotential = data[0].potential_no;
            this.recoveryLeads.numberPhone = data[0].mobile_yes;
            this.recoveryLeads.noNumberPhone = data[0].mobile_no;
            this.recoveryLeads.appointment = data[0].appointment_yes;
            this.recoveryLeads.noAppointment = data[0].appointment_no;
            this.recoveryLeads.countToday = data[0].count_today;
            this.recoveryLeads.countMonth = data[0].count_month;
          }
        }
      } catch (error) {
        this.showErrorSwal();
        console.log(error);
      }
    },
    viewDetailsForAnswer(mode, type) {
      this.typeShow = mode;
      this.clearDataTypeDetails();
      this.propTypeModule = this.typeModuleSelected;
      this.typeDetails = mode;
      this.typeAnswer = type;
      this.showModalLeadsDetails = true;
    },
    viewDetailsForPotential(mode, type) {
      this.typeShow = mode;
      this.clearDataTypeDetails();
      this.propTypeModule = this.typeModuleSelected;
      this.typeDetails = mode;
      this.typePotential = type;
      this.showModalLeadsDetails = true;
    },
    viewDetailsForNumber(mode, type) {
      this.clearDataTypeDetails();
      this.propTypeModule = this.typeModuleSelected;
      this.typeShow = mode;
      this.typeDetails = mode;
      this.typeNumberPhone = type;
      this.showModalLeadsDetails = true;
    },
    viewDetailsForAppointment(mode, type) {
      this.clearDataTypeDetails();
      this.propTypeModule = this.typeModuleSelected;
      this.typeShow = mode;
      this.typeDetails = mode;
      this.typeAppointment = type;
      this.showModalLeadsDetails = true;
    },
    clearDataTypeDetails() {
      this.typeAnswer = null;
      this.typePotential = null;
      this.typeNumberPhone = null;
      this.typeAppointment = null;
    },
    selectedTypeModule(typeModule) {
      this.$emit("changeData", 5, typeModule);
    },
    viewDetails(mode) {
      this.typeShow = 1;
      this.propTypeModule = null;
      this.clearDataTypeDetails();
      this.typeDetails = mode;
      this.showModalLeadsDetails = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.card-leads-dashboard-crm {
  height: 100%;
  gap: 0.5rem;
  .card-new-leads,
  .card-recovery {
    box-shadow: 0px 1px 4px 0px rgb(57, 67, 76, 0.24) !important;
    padding: 1rem;
    margin: 0;
  }
  .card-new-leads {
    width: 55%;
  }
  .card-recovery {
    width: 45%;
  }

  .card-leads-body,
  .card-recovery-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  hr {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .card-title {
    font-size: large;
    font-family: montserrat;
  }
  .number-leads {
    font-size: 2rem;
    font-weight: 1000;
  }
  .card-active {
    border-radius: 5px;
    padding: 1rem;
    background: #edf3ff;
  }
  .card-inactive {
    border-radius: 5px;
    padding: 1rem;
    background: #f4f4f4;
  }
  .card-inactive:hover {
    background: #edf3ff;
  }
  .card-font {
    font-size: 1rem;
    font-weight: 900;
  }
  .card-programs {
    padding: 4px;
  }
  .show-details-container {
    margin-top: 1rem;
    .show-details-button {
      padding: 5px 10px;
    }
  }
  .open-modal-type:hover {
    color: #0090e7;
    border-bottom: 1px solid #0090e7;
  }
  .data-number{
    margin-left: 3px;
  }
}
.dark-layout {
  .card-leads-dashboard-crm {
    .card-new-leads,
    .card-recovery {
      background: #1c1c1e;
      box-shadow: 0px 1px 8px 1px rgb(0 0 0 / 24%) !important;
    }

    .card-active {
      background: #0090e7;
    }
    .card-inactive {
      background: #2d2d2d;
    }
    .card-inactive:hover {
      background: #0090e7;
    }
    .dark-font {
      color: white;
    }
  }
}
</style>
