<template>
  <div class="dashboard-crm">
    <header-slot> </header-slot>
    <div class="cards-container mb-2">
      <card-dashboard
        :key="keySales"
        :icon="`DollarSignIcon`"
        :title="`Sales`"
        :filters="filtersSales"
        :date="currentSalesDate"
        :is-active="tabIndex === 0 || tabIndex === 1"
        :show-details="false"
        @reload="updateSalesCard($event)"
        @click="tabIndex = 0"
      >
        <template #content>
          <card-sales
            :pending-sales-indicators="cardPendingSaleData"
            :done-sales-indicators="cardDoneSaleData"
            :inner-card-selections="innerCardSelections"
            @mark_detail_type="openModalSalesDetailsFromInnerCard($event)"
            @details="openModalSalesDetails($event)"
            @keepInnerCardTimeRateSelectionSync="
              keepInnerCardTimeRateSelectionSync($event)
            "
            @set-tab="setTab"
          />
        </template>
      </card-dashboard>

      <card-dashboard
        :key="keyLeads"
        :icon="`UserIcon`"
        :title="`Leads`"
        :filters="filterLeads"
        :show-details="false"
        :date="objectDataLead.date"
        :is-active="tabIndex === 3"
        @details="openModalLeadsDetails($event)"
        @reload="updateCardLeads($event)"
        @click="tabIndex = 3"
      >
        <template #content>
          <card-leads
            ref="cardLeadDashboardCrm"
            :key="keyLeadCard"
            :is-today-leads-selected="isTodayLeadsSelected"
            :is-today-recovery-selected="isTodayRecoverySelected"
            :type-module-selected="typeModuleSelected"
            :object-data="objectDataLead"
            @changeData="changeDataLeads"
            @click="tabIndex = 3"
          />
        </template>
      </card-dashboard>

      <card-dashboard
        :key="keyAppointments"
        :icon="`CalendarIcon`"
        :title="`Appointments`"
        :filters="filtersAppointments"
        :date="currentDateAppointments"
        :show-details="false"
        :is-active="tabIndex === 2"
        @reload="updateCardAppointments($event)"
        @click="tabIndex = 2"
      >
        <template #content>
          <card-appointments-crm
            :data="appointmentsDashboardData"
            :is-today="isTodayAppointments"
            @changeData="getAppointmentsDashboardData($event)"
            @details="openModalAppointmentDetails($event)"
            @click="tabIndex = 2"
          />
        </template>
      </card-dashboard>
    </div>
    <div class="graphics-container">
      <!-- Graphics appoinments -->
      <main-appointments-reports
        v-if="tabIndex === 2"
        :key="keyAppointments"
        :filters="filtersAppointments"
        :date="currentDateAppointments"
      />
      <!-- Graphics sales -->
      <div v-if="[0, 1].includes(tabIndex)" style="display: flex; gap: 1rem">
        <div style="width: 18%">
          <sales-indicators
            :key="keyFeeSales"
            :pending-sales-indicators="cardPendingSaleData.monthly_sales"
            :done-sales-indicators="cardDoneSaleData.monthly_sales"
          />
        </div>
        <div style="width: 82%">
          <sales-dashboard
            :key="keySalesDashboard"
            :sales-status="currentStatusSale"
            :program-id="currentProgram"
            :seller-id="currentSeller"
            :current-graphic-option-selection="currentTypeGraphicOptionSeleted"
            :date="currentSalesDate"
            @keepSelectionSync="keepSelectionSync($event)"
          />
        </div>
      </div>
      <!-- Graphics Leads -->
      <div v-if="tabIndex == 3">
        <graphics-dashboard-lead
          :key="keyLeadDashboard"
          :object-data="objectDataLead"
        />
      </div>
    </div>
    <!-- {{ currentSalesDateYear }}xd
    {{ filtersSales[0].model }} -->

    <!--
    this.filtersSales[0].model = moment().format("YYYY-MM-DD");
    this.filtersAppointments[0].model = moment().format("YYYY-MM-DD"); -->
    <b-row>
      <b-col cols="6" v-if="isCeo || isChief || isSupervisor">
        <call-analysis
          ref="callAnalysis"
          :select-year="selectYear"
          :select-month="selectMonth"
          :module-id="2"
        >
          <template #calendar>
            <div class="d-flex justify-content-end align-items-center py-1">
              <v-select
                v-model="selectYear"
                :reduce="(year) => year.id"
                :options="years"
                :selectable="(option) => option.selectable === true"
                :clearable="false"
                label="name"
                style="width: 150px"
                class="mr-1"
              />

              <v-select
                v-model="selectMonth"
                :reduce="(month) => month.id"
                :options="months"
                :clearable="false"
                label="name"
                style="width: 190px"
              />
            </div>
          </template>
        </call-analysis>
      </b-col>
       <b-col cols="6">
        <DepartmentExpenses />
      </b-col>
    </b-row>

    <detailed-sales
      v-if="showSalesDetailsModal"
      :p-date="currentSalesDate"
      :status-sale="currentStatusSale"
      :destination-program-id="currentProgram"
      :seller-id="currentSeller"
      :show-daily-sales="showDailySales"
      @close="showSalesDetailsModal = false"
    />
    <modal-appointments-details
      v-if="showModalAppointmentsDetails"
      :program-id="currentProgram"
      :seller-id="currentSeller"
      :answer-option="currentStatusAnswers"
      :sale-option="currentStatusSales"
      :date="currentDateAppointments"
      :is-today="isTodayAppointments"
      @hidden="showModalAppointmentsDetails = false"
    />
  </div>
</template>
<script>
import CardDashboard from "@/views/crm/views/dashboard/components/CardDashboardCrm.vue";
import DashboardService from "@/views/crm/views/dashboard/service/dashboard.service";
import CardAppointmentsCrm from "@/views/crm/views/dashboard/components/appointments/CardAppointmentsCrm.vue";
import DetailedSales from "@/views/crm/views/dashboard/modals/DetailedSales.vue";
import CardLeads from "@/views/crm/views/dashboard/components/leads/CardLeads.vue";
import CardSales from "@/views/crm/views/dashboard/components/sales/CardSales.vue";
import moment from "moment";
import ModalAppointmentsDetails from "@/views/crm/views/dashboard/components/appointments/ModalAppointmentsGrid.vue";
import FilterLeads from "@/views/crm/views/dashboard/filter/dashboard-sidebar-leads-filters.js";
import SidebarFiltersSales from "@/views/crm/views/dashboard/filter/dashboard-sidebar-filter-sales";
import SidebarFiltersAppointments from "@/views/crm/views/dashboard/filter/appointments-sidebar-filters";
import MainAppointmentsReports from "@/views/crm/views/dashboard/components/appointments/MainAppointmentsReports.vue";
import SalesDashboard from "@/views/crm/views/dashboard/components/sales/BarChartSalesCrm.vue";
import GraphicsDashboardLead from "@/views/crm/views/dashboard/components/leads/GraphicsDashboardLead";
import SalesIndicators from "@/views/crm/views/dashboard/components/sales/SalesIndicators.vue";
import CallAnalysis from "@/views/ceo/dashboard/CallAnalysis.vue";
import DepartmentExpenses from "@/views/commons/expenses/components/department/DepartmentExpenses.vue";

export default {
  name: "Dashboard",
  components: {
    CardDashboard,
    CardAppointmentsCrm,
    DetailedSales,
    CardLeads,
    CardSales,
    ModalAppointmentsDetails,
    MainAppointmentsReports,
    SalesDashboard,
    GraphicsDashboardLead,
    SalesIndicators,
    CallAnalysis,
    DepartmentExpenses
  },
  data() {
    return {
      selectMonth: null,
      selectYear: null,
      optionsMonth: [
        { id: 1, name: "January" },
        { id: 2, name: "February" },
        { id: 3, name: "March" },
        { id: 4, name: "April" },
        { id: 5, name: "May" },
        { id: 6, name: "June" },
        { id: 7, name: "July" },
        { id: 8, name: "August" },
        { id: 9, name: "September" },
        { id: 10, name: "October" },
        { id: 11, name: "November" },
        { id: 12, name: "December" },
      ],
      optionsYears: [],
      currentTypeGraphicOptionSeleted: 1,
      tabIndex: 0,
      keySalesDashboard: Math.random(),
      filtersSales: SidebarFiltersSales,
      filtersAppointments: SidebarFiltersAppointments,
      cardDoneSaleStatus: "DONE",
      cardPendingSaleStatus: "PENDING",
      currentSalesDate: null,
      currentProgram: null,
      currentSeller: null,
      currentStatusSale: "PENDING",
      date: null,
      innerCardSelections: {
        active_daily_done: true,
        active_monthly_done: false,
        active_daily_pending: true,
        active_monthly_pending: false,
      },
      sellerOptions: [],
      programOptions: [],
      defaultSellerOptions: { id: null, user_name: "All sellers" },
      defaultProgramsOptions: {
        id: null,
        value: "All programs",
        name: "All programs",
        initial: "AP",
      },
      keySales: Math.random(),
      keyLeads: Math.random(),
      keyAppointments: Math.random(),
      showSalesDetailsModal: false,
      cardDoneSaleData: {},
      cardPendingSaleData: {},
      showDailySales: true,
      showModalAppointmentsDetails: false,
      isTodayAppointments: true,
      appointmentsDashboardData: {
        totalToday: 0,
        totalMonth: 0,
        totalAppointments: 0,
        totalAnswered: 0,
        totalUnanswered: 0,
        totalSalesMade: 0,
        totalSalesPending: 0,
      },
      appointmentsItems: [],
      keyLeadCard: 0,
      filterLeads: FilterLeads,
      isTodayLeadsSelected: true,
      isTodayRecoverySelected: true,
      objectDataLead: {},
      keyLeadCard: 0,
      originModuleOptions: [],
      keyFeeSales: Math.random(),
      keyIpSales: Math.random(),
      keyLeadDashboard: Math.random(),
      currentDateAppointments: moment().format("YYYY-MM-DD"),
      typeModuleSelected: 1,
      currentStatusSales: null,
      currentStatusAnswers: null,
      today: new Date(),
    };
  },
  computed: {
    currentSalesDateYear() {
      const currentDate = moment(this.currentSalesDate, "YYYY-MM-DD");
      const yearNumber = currentDate.format("YYYY");
      return yearNumber;
    },
    changeProgramAppointments() {
      return this.filtersAppointments[1].model;
    },
    changeProgramSales() {
      return this.filtersSales[1].model;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    months() {
      if (this.selectYear === this.today.getFullYear()) {
        return this.optionsMonth.filter(
          (month) => month.id <= this.today.getMonth() + 1
        );
      }
      return this.optionsMonth;
    },
    years() {
      // Reset selectable mode for all years
      this.optionsYears.forEach((year) => {
        year.selectable = true;
      });

      // Disable future years if the selected month is greater than the current month
      const currentMonth = this.today.getMonth() + 1;
      if (this.selectMonth > currentMonth) {
        const lastYearIndex = this.optionsYears.length - 1;
        this.optionsYears[lastYearIndex].selectable = false;
      }

      return this.optionsYears;
    },
  },
  watch: {
    currentSalesDateYear(newYear, oldYear) {
      if (newYear !== oldYear) {
        this.keySalesDashboard = Math.random();
      }
    },
    changeProgramSales: {
      handler: function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.getSellers(newValue, "sales");
        }
      },
      deep: true,
    },
    changeProgramAppointments: {
      handler: function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.getSellers(newValue, "appointments");
        }
      },
      deep: true,
    },
    currentProgram() {
      this.keySalesDashboard = Math.random();
    },
    currentSeller() {
      this.keySalesDashboard = Math.random();
    },
  },
  async created() {
    this.addPreloader();
    this.generateYears();
    this.currentMonth();
    this.updateCardLeads();
    this.filtersSales[0].model = moment().format("YYYY-MM-DD");
    this.filtersAppointments[0].model = moment().format("YYYY-MM-DD");
    this.currentSalesDate = this.filtersSales[0].model;
    await Promise.all([
      this.getPrograms(),
      this.getSellers(),
      this.getOriginModules(),
      this.getCrmDashboardMonthlySales(this.cardDoneSaleStatus),
      this.getCrmDashboardMonthlySales(this.cardPendingSaleStatus),
      this.getAppointmentsDashboardData(this.isTodayAppointments ? 1 : 2),
    ]);
  },
  mounted() {},
  methods: {
    generateYears() {
      const today = new Date();
      const currentYear = today.getFullYear();
      const beginningYear = 2023;
      this.optionsYears = [];

      for (let i = beginningYear; i <= currentYear; i++) {
        this.optionsYears.push({ id: i, name: i, selectable: true });
      }

      this.selectYear = currentYear;
    },
    currentMonth() {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      this.selectMonth = currentMonth;
    },
    async updateSalesCard() {
      this.currentSalesDate = this.filtersSales[0].model;
      this.currentProgram = this.filtersSales[1].model;
      this.currentSeller = this.filtersSales[2].model;
      await this.getCrmDashboardMonthlySales(this.cardDoneSaleStatus);
      await this.getCrmDashboardMonthlySales(this.cardPendingSaleStatus);
    },
    async getCrmDashboardMonthlySales(cardStatus) {
      try {
        const params = {
          type: "card_indicators",
          status_sale: cardStatus,
          program_id: this.filtersSales[1].model,
          date: this.filtersSales[0].model,
          seller_id: this.filtersSales[2].model,
        };
        const { data } = await DashboardService.getCrmDashboardMonthlySales(
          params
        );
        switch (cardStatus) {
          case "DONE":
            this.cardDoneSaleData = data;
            break;
          case "PENDING":
            this.cardPendingSaleData = data;
            break;
          default:
            break;
        }
        // this.keySales = Math.random();
        this.keyFeeSales = Math.random();
        this.keyIpSales = Math.random();
        this.cardSaleData = data;
      } catch (error) {
        console.error(error);
      }
    },
    async getSellers(programOptionId = null, type = null) {
      try {
        const moduleId = this.convertProgramToModule(programOptionId);
        const params = {
          roles: "[1,5,2,3,13]",
          type: "1",
        };
        const data = await DashboardService.getSellers(moduleId ?? 2, params);

        const uniqueData = data.filter(
          (value, index, self) =>
            self.findIndex((t) => t.id === value.id) === index
        );
        const sellers = [this.defaultSellerOptions, ...uniqueData];

        if (type === "appointments" || type === null) {
          this.filtersAppointments[2].model = null;
          this.filtersAppointments[2].options = [];
          this.filtersAppointments[2].options = sellers;
        }

        if (type === "sales" || type === null) {
          this.filtersSales[2].model = null;
          this.filtersSales[2].options = [];
          this.filtersSales[2].options = sellers;
        }
      } catch (error) {
        console.error("Error in getSellers Dashboard CRM:", error);
      }
    },
    async getPrograms() {
      try {
        const { data } = await DashboardService.getPrograms();
        this.filtersSales[1].options = [];
        this.filtersAppointments[1].options = [];
        this.filtersSales[1].options = [this.defaultProgramsOptions, ...data];
        this.filtersAppointments[1].options = [
          this.defaultProgramsOptions,
          ...data,
        ];
      } catch (error) {
        this.showErrorSwal();
      }
    },
    async openModalSalesDetails(event) {
      this.openModalSalesDetailsFromInnerCard(event);
      this.currentSalesDate = this.filtersSales[0].model;
      this.currentProgram = this.filtersSales[1].model;
      this.currentSeller = this.filtersSales[2].model;
      this.showSalesDetailsModal = true;
    },

    async openModalSalesDetailsFromInnerCard(saleStatusType) {
      switch (saleStatusType) {
        case "daily_done":
          this.currentStatusSale = "DONE";
          this.showDailySales = true;
          break;
        case "daily_pending":
          this.currentStatusSale = "PENDING";
          this.showDailySales = true;
          break;
        case "monthly_done":
          this.currentStatusSale = "DONE";
          this.showDailySales = false;
          break;
        case "monthly_pending":
          this.currentStatusSale = "PENDING";
          this.showDailySales = false;
          break;
        default:
          break;
      }
    },
    closeModalSalesDetails() {
      this.showSalesDetailsModal = false;
    },
    async openModalAppointmentDetails(event) {
      switch (event) {
        case 0:
          this.currentStatusAnswers = null;
          this.currentStatusSales = null;
          break;
        case 1:
          this.currentStatusAnswers = 1;
          this.currentStatusSales = null;
          break;
        case 2:
          this.currentStatusAnswers = 0;
          this.currentStatusSales = null;
          break;
        case 3:
          this.currentStatusAnswers = null;
          this.currentStatusSales = 1;
          break;
        case 4:
          this.currentStatusAnswers = null;
          this.currentStatusSales = 0;
          break;
      }
      this.currentDateAppointments = this.filtersAppointments[0].model;
      this.currentProgram = this.filtersAppointments[1].model;
      this.currentSeller = this.filtersAppointments[2].model;
      this.showModalAppointmentsDetails = true;
    },
    async getAppointmentsDashboardData(type) {
      try {
        this.addPreloader();
        const params = {
          program_id: this.filtersAppointments[1].model,
          seller_id: this.filtersAppointments[2].model,
          date: this.filtersAppointments[0].model,
          slug: "card-appointments-crm",
        };
        const { data } = await DashboardService.getAppointmentsDashboardData(
          params
        );
        if (data.length === 0) return;
        this.processDataAppointments(type, data[0]);
      } catch (error) {
        this.showErrorSwal();
      } finally {
        this.removePreloader();
      }
    },
    async processDataAppointments(type, data) {
      this.appointmentsDashboardData.totalToday = data.total_appointments_day;
      this.appointmentsDashboardData.totalMonth = data.total_appointments_month;
      if (type === 1) {
        this.isTodayAppointments = true;
        this.appointmentsDashboardData.totalAppointments =
          data.total_appointments_day;
        this.appointmentsDashboardData.totalAnswered =
          data.total_calls_answered_day;
        this.appointmentsDashboardData.totalUnanswered =
          data.total_unanswered_calls_day;
        this.appointmentsDashboardData.totalSalesMade =
          data.total_sales_made_day;
        this.appointmentsDashboardData.totalSalesPending =
          data.total_sales_pending_day;
      } else {
        this.isTodayAppointments = false;
        this.appointmentsDashboardData.totalAppointments =
          data.total_appointments_month;
        this.appointmentsDashboardData.totalAnswered =
          data.total_calls_answered_month;
        this.appointmentsDashboardData.totalUnanswered =
          data.total_unanswered_calls_month;
        this.appointmentsDashboardData.totalSalesMade =
          data.total_sales_made_month;
        this.appointmentsDashboardData.totalSalesPending =
          data.total_sales_pending_month;
      }
    },
    async updateCardAppointments() {
      this.getAppointmentsDashboardData(this.isTodayAppointments ? 1 : 2);
      this.currentDateAppointments = this.filtersAppointments[0].model;
      this.keyAppointments = Math.random();
    },
    updateCardLeads() {
      this.objectDataLead = {
        originModule: this.filterLeads.find(
          (item) => item.key == "originModule"
        ).model,
        date: this.filterLeads.find((item) => item.key == "date").model,
      };
      this.keyLeadCard = Math.random();
      this.keyLeadDashboard = Math.random();
    },
    async changeDataLeads(value, typeModule) {
      switch (value) {
        case 1:
          this.isTodayLeadsSelected = true;
          await this.$refs.cardLeadDashboardCrm.getLeadsDashboardData(
            1,
            1,
            typeModule
          );
          break;
        case 2:
          this.isTodayLeadsSelected = false;
          await this.$refs.cardLeadDashboardCrm.getLeadsDashboardData(
            1,
            2,
            typeModule
          );
          break;
        case 3:
          this.isTodayRecoverySelected = true;
          await this.$refs.cardLeadDashboardCrm.getLeadsDashboardData(
            2,
            1,
            typeModule
          );
          break;
        case 4:
          this.isTodayRecoverySelected = false;
          await this.$refs.cardLeadDashboardCrm.getLeadsDashboardData(
            2,
            2,
            typeModule
          );
          break;
        case 5:
          this.typeModuleSelected = typeModule;
          await this.$refs.cardLeadDashboardCrm.getLeadsDashboardData(
            1,
            this.isTodayLeadsSelected == 1 ? 1 : 2,
            typeModule
          );
          break;
      }
    },
    async getOriginModules() {
      try {
        const { data } = await DashboardService.getOriginModules();
        this.originModuleOptions = [
          { id: null, value: "All Modules" },
          ...data,
        ];
        this.filterLeads.find((item) => item.key == "originModule").options =
          this.originModuleOptions;
        this.keyLeads = 0;
      } catch (error) {
        this.showErrorSwal();
      }
    },
    setTab(tab) {
      switch (tab) {
        case 0:
          this.currentStatusSale = "DONE";
          break;
        default:
          this.currentStatusSale = "PENDING";
          break;
      }
      this.tabIndex = tab;
    },
    keepSelectionSync(option) {
      this.currentTypeGraphicOptionSeleted = option;
    },
    keepInnerCardTimeRateSelectionSync(options) {
      this.innerCardSelections = { ...options };
    },
  },
};
</script>
<style lang="scss">
.dashboard-crm {
  .cards-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
  .graphics-container {
    border-top: 2px solid #0090e7;
    padding: 1rem 0;
  }
  @media screen and (min-width: 768px) {
    .cards-container {
      grid-template-columns: 1.2fr 0.8fr;
    }
    .card-dashboard-crm:nth-child(3) {
      grid-row: 1;
      grid-column: 2;
    }
    .card-dashboard-crm:nth-child(2) {
      grid-column-end: span 2;
    }
  }
  @media screen and (min-width: 1140px) {
    .cards-container {
      grid-template-columns: 0.8fr 1.2fr;
    }
    .card-dashboard-crm:nth-child(3) {
      grid-row: 2;
      grid-column: 1;
    }
    .card-dashboard-crm:nth-child(2) {
      grid-column-end: span 1;
    }
  }
  @media screen and (min-width: 1450px) {
    .cards-container {
      grid-template-columns: 0.9fr 1.3fr 0.7fr;
    }
    .card-dashboard-crm:nth-child(3) {
      grid-row: 1;
      grid-column: 3;
    }
  }
}
</style>
