<template>
  <b-sidebar
    id="sidebar-rigth-filters"
    :visible="showSidebar"
    right
    backdrop
    bg-variant="white"
    sidebar-class="sidebar-lg"
    header-class="pt-1"
    :no-close-on-backdrop="false"
    :no-close-on-esc="false"
    lazy
    @hidden="$emit('closeSidebar')"
  >
    <template #header>
      <div class="d-flex justify-content-between align-items-center w-100">
        <span>
          <h3>Advanced Search {{ title }}</h3>
        </span>
        <span class="cursor-pointer" @click="$emit('closeSidebar')">
          <tabler-icon icon="XIcon" size="20" />
        </span>
      </div>
    </template>
    <b-container>
      <filters-component :filters="filters" />
    </b-container>
    <template #footer>
      <b-container>
        <b-row class="d-flex p-1 float-right">
          <b-button variant="info" @click="resetFiltersButtons">
            Reset
          </b-button>
          <b-button variant="primary" class="ml-1" @click="sideBarSearch">
            Search
          </b-button>
        </b-row>
      </b-container>
    </template>
  </b-sidebar>
</template>
<script>
export default {
  name: "SidebarCrm",
  props: {
    title: {
      type: String,
      default: "Sidebar Title",
    },
    showSidebar: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    resetFiltersButtons() {
      if (this.filters.length === 0) return;
      this.filters.forEach((filter) => {
        filter.model = null;
      });
    },
    sideBarSearch() {
      this.$emit("reload", this.filters);
    },
  },
};
</script>
<style lang="scss">
</style>