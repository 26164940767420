<template>
  <b-modal
    id="modal-detailed-sales"
    v-model="onControl"
    header-bg-variant="transparent border-bottom border-bottom-2"
    size="caro"
    header-class="p-0"
    title="Sales Detail"
    modal-class="modal-primary"
    hide-footer
    scrollable
    @hidden="close()"
  >
    <template v-slot:modal-header>
      <div class="header-modal">
        <span
          >{{ capitalizeFirstLetter(statusSale) }} Sales Details -
          {{ showDateInTitle }}</span
        >
        <div
          class="container-icons"
          :class="isDarkSkin ? 'container-icons--dark' : ''"
          @click="$emit('close')"
        >
          <feather-icon
            icon="XIcon"
            size="18"
            class="pointer"
            :class="isDarkSkin ? 'text-light' : 'text-primary'"
          />
        </div>
      </div>
    </template>

    <filter-slot
      id="crm-detailed-sales-filter"
      :filter="visibleFilters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :top-paginate="false"
      @reload="$refs['refTableSalesDetailed'].refresh()"
    >
      <template #table>
        <b-table
          ref="refTableSalesDetailed"
          no-border-collapse
          class="position-relative px-1"
          :class="
            isDarkSkin
              ? 'table-dark-detailed-sales'
              : 'table-light-detailed-sales'
          "
          :fields="visibleFields"
          show-empty
          no-provider-filtering
          sticky-header="55vh"
          primary-key="id"
          responsive="sm"
          :items="searchDetailedSales"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #head(client_name)="data">
            <div class="text-left">
              {{ data.label }}
            </div>
          </template>
          <template #head(add_or_change_service)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(fee)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(ip)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(last_call_at)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(approved_at)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #head(created_at)="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template #cell(destination_program_id)="data">
            <div class="charge__programs text-center">
              <img
                :src="getProgramImageRoute(data.item.destination_program_id)"
                alt="img_services"
              />
            </div>
          </template>
          <template #cell(add_or_change_service)="data">
            <div class="text-center">
              <b-badge
                :id="`show-program-origin-${data.item.sale_id}`"
                v-if="!data.item.add_or_change_service"
                class="cursor-pointer"
                style="padding: 10px 15px"
                pill
                variant="light-success"
              >
                <span>
                  {{ "NEW" }}
                </span>
              </b-badge>
              <div v-else :id="`show-program-origin-${data.item.sale_id}`">
                <b-badge
                  v-if="data.item.add_or_change_service === 1"
                  class="cursor-pointer"
                  style="padding: 10px 15px"
                  pill
                  variant="light-primary"
                >
                  <span>
                    {{ "ADD" }}
                  </span>
                </b-badge>
                <b-badge
                  v-if="data.item.add_or_change_service === 2"
                  class="cursor-pointer"
                  style="padding: 10px 15px"
                  pill
                  variant="light-warning"
                >
                  <span>
                    {{ "CHANGE" }}
                  </span>
                </b-badge>
              </div>
              <b-tooltip
                :target="`show-program-origin-${data.item.sale_id}`"
                triggers="hover"
                placement="right"
              >
                <div class="d-flex tooltip-origin-program-container">
                  <div class="tooltip-origin-program-from">
                    {{ "From :" }}
                  </div>
                  <div class="tooltip-origin-program-name">
                    {{ data.item.origin_program_name }}
                  </div>
                  <div class="tooltip-charge__programs text-center">
                    <img
                      :src="getProgramImageRoute(data.item.origin_program_id)"
                      alt="img_services"
                    />
                  </div>
                </div>
              </b-tooltip>
            </div>
          </template>
          <template #cell(client_name)="data">
            <div class="d-flex">
              <div v-if="isCeo">
                <router-link
                  v-if="statusSale === 'DONE'"
                  class="text-center"
                  target="_blank"
                  :to="
                    routerDashboardRedirectioner(
                      data.item.destination_program_id,
                      data.item.client_account_id
                    )
                  "
                >
                  {{ data.item.client_name }} <br />
                  {{ data.item.account }}
                </router-link>
                <div v-else>
                  <div style="white-space: pre-wrap">
                    <router-link
                      :class="textLink"
                      :to="`/crm/leads/${data.item.lead_id}`"
                      target="_blank"
                      >{{ data.item.client_name }}</router-link
                    >
                  </div>
                </div>
              </div>
              <div v-else>
                <div style="white-space: pre-wrap">
                  <router-link
                    :class="textLink"
                    :to="`/crm/leads/${data.item.lead_id}`"
                    target="_blank"
                    >{{ data.item.client_name }}</router-link
                  >
                </div>
              </div>
            </div>
          </template>
          <template #cell(seller_name)="data">
            <div>
              <div style="font-size: 13px">
                <b-avatar
                  :src="data.item.user_thumb"
                  size="1.8rem"
                  variant="light-primary"
                  class="mr-1"
                />
                {{ data.item.seller_name }}
              </div>
            </div>
          </template>
          <template #cell(fee)="data">
            <div class="align-middle text-center text-money">
              {{ data.item.fee | currencyZero }}
            </div>
          </template>

          <template #cell(ip)="data">
            <div class="align-middle text-center text-money">
              {{ data.item.ip | currencyZero }}
            </div>
          </template>
          <template #cell(approved_at)="data">
            <div class="align-middle text-center">
              {{ data.item.approved_at | myGlobalWithHour }}
            </div>
          </template>
          <template #cell(created_at)="data">
            <div class="align-middle text-center">
              {{ data.item.created_at | myGlobalWithHour }}
            </div>
          </template>
          <template #cell(last_call_at)="data">
            <div class="align-middle text-center">
              <span>
                {{ data.item.last_call_at | myGlobalWithHour }}
              </span>
              <feather-icon
                v-if="data.item.last_call_at"
                v-b-tooltip.hover-right="'Who made the call?'"
                size="18"
                icon="AlertCircleIcon"
                class="ml-1 cursor-pointer text-info"
                @click="openWhoMadeTheCallModal(data.item.lead_id)"
              />
            </div>
          </template>
          <template #bottom-row>
            <b-th colspan="3" class="fill-bottom-row" />
            <b-th class="py-0 fill-bottom-row" colspan="3">
              <b-row class="m-0 p-0 fill-bottom-row">
                <b-col
                  cols="4"
                  class="widthRecovery text-center p-0 text-black text-center"
                >
                  <b-badge
                    variant="primary"
                    class="w-100 text-center detailed-sales-total-left"
                  >
                    TOTAL
                  </b-badge>
                </b-col>
                <b-col
                  cols="4"
                  class="widthRecovery p-0 text-black text-center"
                >
                  <b-badge
                    variant="primary"
                    class="w-100 text-center detailed-sales-total-center"
                  >
                    <span class="pl-5">
                      {{ "$ " }}{{ totalFee | currencyZero }}
                    </span>
                  </b-badge>
                </b-col>
                <b-col
                  cols="4"
                  class="widthRecovery p-0 text-black text-center"
                >
                  <b-badge
                    variant="primary"
                    class="w-100 text-center detailed-sales-total-right"
                  >
                    <span class="pl-5">
                      {{ "$ " }}{{ totalAmount | currencyZero }}
                    </span>
                  </b-badge>
                </b-col>
              </b-row>
            </b-th>
            <b-th class="fill-bottom-row" colspan="2" />
          </template>
        </b-table>
      </template>
    </filter-slot>
    <detailed-list-calls
      v-if="showWhoMadeTheCallModal"
      :lead-id="currentLeadId"
      @close="
        showWhoMadeTheCallModal = false;
        currentLeadId = null;
      "
    />
  </b-modal>
</template>
<script>
import CrmDashboardServive from "@/views/crm/views/dashboard/service/dashboard.service";
import ProgramsFilter from "@/views/crm/views/dashboard/filter/programs-filter";
import DashboardService from "@/views/crm/views/dashboard/service/dashboard.service";
import DetailedListCalls from "@/views/crm/views/dashboard/modals/DetailedListCalls.vue";
import Fields from "@/views/crm/views/dashboard/field/detailed-sales-fields";
import moment from "moment";

export default {
  components: {
    DetailedListCalls,
  },
  props: {
    pDate: {
      type: String,
      required: false,
    },
    statusSale: {
      type: String,
      required: true,
    },
    destinationProgramId: {
      type: Number,
      default: null,
    },
    sellerId: {
      type: Number,
      default: null,
    },
    showDailySales: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentLeadId: null,
      onControl: false,
      startPage: 0,
      toPage: 0,
      isBusy: false,
      filters: ProgramsFilter,
      dataYear: null,
      dataMonth: null,
      titleModal: "",
      idPrograms: null,
      initialProgram: "",
      totalFee: 0,
      totalAmount: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by client name...",
        model: "",
      },
      fields: Fields,
      defaultProgramOptions: [
        {
          id: null,
          value: "All programs",
          name: "All programs",
          initial: "AP",
        },
      ],
      programOptions: [],
      salesDetailedItems: [],
      sellerOptions: [],
      showWhoMadeTheCallModal: false,
      defaultSellerOptions: { id: null, user_name: "All sellers" },
      totalRows: 1,
      triggerProvider: false,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },
    };
  },

  computed: {
    currentProgramName() {
      return this.programOptions.find((p) => p.id === this.filters[0].model)
        ?.name;
    },
    visibleFilters() {
      return this.filters.filter((f) => f.visible);
    },
    visibleFields() {
      return this.fields.filter((f) => f.visible);
    },
    monthNameInTitle() {
      const currentDate = moment(this.pDate, "YYYY-MM-DD");
      const monthName = currentDate.format("MMMM");
      const yearNumber = currentDate.format("YYYY");

      return `${monthName} ${yearNumber}`;
    },
    dayInTitle() {
      return moment(this.pDate).format("MMMM DD, YYYY");
    },
    filterDestinationProgram() {
      return this.filters[0].model;
    },
    showDateInTitle() {
      return this.showDailySales ? this.dayInTitle : this.monthNameInTitle;
    },
  },
  watch: {
    async filterDestinationProgram() {
      await this.getSellers();
    },
  },
  mounted() {},
  async created() {
    this.onControl = true;
    await Promise.all([this.getPrograms(), this.getSellers()]);
    this.setFieldsVisibilityOnSaleStatus(this.statusSale);
    this.filters[0].model = this.destinationProgramId;
    this.filters[1].model = this.sellerId;
    this.triggerProvider = true;
    if (this.triggerProvider) {
      this.$refs.refTableSalesDetailed.refresh();
    }
  },

  methods: {
    close() {
      this.$emit("close");
    },
    async searchDetailedSales() {
      if (!this.triggerProvider) return [];
      const params = {
        date: this.pDate,
        type: "modal_detailed_data",
        destination_program_id: this.filters[0].model,
        page: this.paginate.currentPage,
        perpage: this.paginate.perPage,
        search_text: this.filterPrincipal.model,
        status_sale: this.statusSale.toUpperCase(),
        seller_id: this.filters[1].model,
        type_sale: this.filters[2].model,
        show_daily_sales: this.showDailySales,
      };

      const { data } = await CrmDashboardServive.getCrmDashboardMonthlySales(
        params
      );

      this.salesDetailedItems = data.data;
      this.startPage = data.from;
      this.paginate.currentPage = data.current_page;
      this.paginate.perPage = data.per_page;
      this.totalRows = data.total;
      this.toPage = data.to;
      this.totalFee = this.salesDetailedItems[0]?.total_fee_amount;
      this.totalAmount = this.salesDetailedItems[0]?.total_initial_payment;
      return this.salesDetailedItems || [];
    },
    async getPrograms() {
      try {
        const { data } = await CrmDashboardServive.getPrograms();
        this.filters[0].options = [...this.defaultProgramOptions, ...data];
        this.programOptions = this.filters[0].options;
      } catch (error) {
        console.error(error);
      }
    },
    async getSellers() {
      try {
        const moduleId = this.convertProgramToModule(this.filters[1].model);
        const params = {
          roles: "[]",
          type: "1",
        };
        const data = await DashboardService.getSellers(moduleId ?? 2, params);
        this.sellerOptions = [];
        this.sellerOptions = [this.defaultSellerOptions, ...data];
        this.filters[1].options = this.sellerOptions;
      } catch (error) {
        console.error("Error in getSellers Dashboard CRM:", error);
      }
    },
    setFieldsVisibilityOnSaleStatus(statusSale) {
      switch (statusSale) {
        case "PENDING":
          this.fields = this.setFieldVisibility(
            this.fields,
            "approved_at",
            false
          );
          break;
        case "DONE":
          this.fields = this.setFieldVisibility(
            this.fields,
            "last_call_at",
            false
          );
          break;
        default:
          break;
      }
    },
    openWhoMadeTheCallModal(leadId) {
      this.currentLeadId = leadId;
      this.showWhoMadeTheCallModal = true;
    },
    closeWhoMadeTheCallModal() {
      this.currentLeadId = null;
      this.showWhoMadeTheCallModal = false;
    },
    capitalizeFirstLetter(str) {
      if (typeof str !== "string" || str.length === 0) {
        console.error("Please provide a valid non-empty string.");
        return null;
      }
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
    addSearchIconInFilter() {
      const iconElement = document.createElement("i");
      iconElement.setAttribute("data-v-04a348xasdg48", "");
      iconElement.classList.add("search-icon");
      const div = document.createElement("div");
      div.setAttribute("data-v-04a348xasdg48", "");
      div.appendChild(iconElement);
      setTimeout(() => {
        const inputFilterParent = document.querySelector(
          "#inner-duo-filter-container"
        );
        const { firstChild } = inputFilterParent;
        inputFilterParent.insertBefore(iconElement, firstChild);
      }, 10);
    },
  },
};
</script>
<style lang="scss" scoped>
.text-money {
  font-weight: 500;

  &::before {
    content: "$";
  }
}

.header-modal {
  position: relative;
  padding: 20px 0 20px 15px;
  background: rgba(22, 84, 204, 1);
  width: 100%;
  font-size: 22px;
  display: flex;
  justify-content: space-betweeen;

  span {
    font-size: 1.6rem;
    font-weight: bold;
    margin-left: 8px;
    color: #ffffff;
  }

  .container-icons {
    background-color: #ffffff;
    padding: 2.7px 6.37px;
    border-radius: 0.357rem;
    width: 31px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 20px;
    transition: all 0.23s ease 0.1s;

    &--dark {
      background-color: #17171a;
    }

    &:hover {
      transform: translate(-7px, 5px);
      cursor: pointer;
    }
  }
}

.charge__programs {
  width: 47px;
  height: 40px;
  background-color: #e9e9e9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 35px;
    height: 35px;
  }
}

.tooltip-charge__programs {
  width: 27;
  height: 20px;
  background-color: #e9e9e9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  img {
    width: 25px;
    height: 25px;
  }
}

.tooltip-origin-program-from {
  padding-top: 3px;
  padding-left: 5px;
  font-size: 14px;
  font-weight: 900;
  margin-right: 5px;
}

.tooltip-origin-program-container {
  padding: 5px 10px;
}

.tooltip-origin-program-name {
  padding-top: 3px;
  font-size: 14px;
  font-weight: 700;
  margin-right: 5px;
}
</style>
<style>
.b-table-bottom-row {
  position: sticky;
  bottom: 0px;
  z-index: 1;
}
.detailed-sales-total-left {
  font-size: 16px;
  border-radius: 0px 0px 0px 15px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 5px;
}

.detailed-sales-total-center {
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.detailed-sales-total-right {
  font-size: 16px;
  border-radius: 0px 0px 15px 0px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: -10px;
}

#modal-detailed-sales table {
  border-collapse: separate !important;
  border-spacing: 0px 8px !important;
}

.table-dark-detailed-sales tr td {
  border: none !important;
  background: #1b1b1e !important;
}

.table-dark-detailed-sales tr td:first-child {
  border-radius: 10px 0 0 10px !important;
}

.table-dark-detailed-sales tr td:last-child {
  border-radius: 0 10px 10px 0 !important;
  background: #1b1b1e !important;
}

.table-dark-detailed-sales tr td {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.table-light-detailed-sales tr td {
  border: none !important;
  background: #fafafa !important;
}

.table-light-detailed-sales tr td:first-child {
  border-radius: 10px 0 0 10px !important;
}

.table-light-detailed-sales tr td:last-child {
  border-radius: 0 10px 10px 0 !important;
  background: #fafafa !important;
}

.table-light-detailed-sales tr td {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.table-dark-detailed-sales th {
  border-top: none !important;
}
.table-light-detailed-sales th {
  border-top: none !important;
}

/* Filter modifiers */
#crm-detailed-sales-filter #container-filters-selector {
  margin-top: 20px !important;
  margin-bottom: 15px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  border-radius: 10px !important;
  background-color: #fafafa !important;
}

.dark-layout #crm-detailed-sales-filter #container-filters-selector {
  margin-top: 20px !important;
  margin-bottom: 15px !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  border-radius: 10px !important;
  background-color: #1b1b1e !important;
}

#crm-detailed-sales-filter #filter-slot-seach-input {
  border-radius: 10px !important;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
  padding-left: 45px !important;
}
#crm-detailed-sales-filter #filter-slot-search-icon {
  display: none;
}

#crm-detailed-sales-filter #inner-duo-filter-container {
  width: 60% !important;
}

#crm-detailed-sales-filter #inner-duo-filter-container button {
  border-radius: 10px !important;
}

#crm-detailed-sales-filter #inner-duo-filter-container button div {
  padding: 3px !important;
}

#crm-detailed-sales-filter #inner-duo-filter-container button div svg {
  width: 20px !important;
  height: 20px !important;
}

#crm-detailed-sales-filter .page-item button {
  border-radius: 5px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}
#crm-detailed-sales-filter .page-item span {
  border-radius: 5px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}
#crm-detailed-sales-filter #filters-selector {
  display: flex;
  flex-direction: row-reverse !important;
  justify-content: space-between;
}
#crm-detailed-sales-filter #filters-selector #filters-selector__pages {
  justify-content: flex-end !important;
}
</style>
<style lang="scss">
.fill-bottom-row {
  background-color: #ffffff !important;
}

.dark-layout .fill-bottom-row {
  background-color: #17171a !important;
  border-top: none !important;
}
.search-icon {
  position: absolute;
  width: 22px;
  height: 22px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" stroke="%23ffffff" height="50px"><path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z"/></svg>')
    no-repeat;
  background-size: contain;
  left: 30px;
  z-index: 999;
}
</style>
