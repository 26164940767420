<template>
  <div class="graphics-dashboard-lead">
    <b-row class="d-flex">
      <b-col cols="2">
        <b-card class="card-graphic">
          <donut-lead-comparison :object-data="objectData" />
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card class="card-graphic">
          <donut-answer :object-data="objectData" />
        </b-card>
      </b-col>
      <b-col cols="8">
        <b-card class="card-graphic">
          <bar-comparison-lead v-if="true" :object-data="objectData" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import DonutLeadComparison from "@/views/crm/views/dashboard/components/leads/graphics/DonutLeadComparison.vue";
import DonutAnswer from "@/views/crm/views/dashboard/components/leads/graphics/DonutAnswer.vue";
import BarComparisonLead from "@/views/crm/views/dashboard/components/leads/BarChartLeadsCrm.vue";
export default {
  components: {
    DonutLeadComparison,
    DonutAnswer,
    BarComparisonLead,
  },
  props: {
    objectData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  computed: {},
  async created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
.graphics-dashboard-lead {
  .card-graphic {
    box-shadow: unset !important;
  }
}
</style>
