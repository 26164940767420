<template>
  <div class="main-appointments-reports">
    <b-row>
      <b-col cols="2">
        <pie-chart-appointments-comparision :filters="filters" :date="date" />
      </b-col>
      <b-col cols="2">
        <pie-chart-sales-comparision :filters="filters" :date="date" />
      </b-col>
      <b-col cols="8">
        <bar-chart-appointments-dashboard :filters="filters" :date="date" />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import PieChartAppointmentsComparision from "@/views/crm/views/dashboard/components/appointments/graphics/PieChartAppointmentsComparision.vue";
import PieChartSalesComparision from "@/views/crm/views/dashboard/components/appointments/graphics/PieChartSalesComparision.vue";
import BarChartAppointmentsDashboard from "@/views/crm/views/dashboard/components/appointments/graphics/BarChartAppointmentsCrm.vue";
export default {
  name: "MainAppointmentsReports",
  components: {
    BarChartAppointmentsDashboard,
    PieChartAppointmentsComparision,
    PieChartSalesComparision,
  },
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    date: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
</style>