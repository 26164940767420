<template>
  <div class="card-dashboard-crm">
    <b-card
      class="card-dashboard cursor-pointer"
      :class="{ 'card-dashboard--active': isActive }"
      @click="$emit('click')"
    >
      <template #header>
        <div class="card-header-title">
          <feather-icon :icon="icon" size="18" />
          {{ title }} - {{ checkToday }}
        </div>
        <div class="card-header-actions">
          <feather-icon
            icon="FilterIcon"
            size="18"
            class="cursor-pointer"
            v-b-tooltip.bottom="'Advanced Search'"
            @click="showSidebar = true"
          />
          <feather-icon
            v-if="showDetails"
            v-b-tooltip.hover.bottom="`View Details in ${title}`"
            icon="InfoIcon"
            size="18"
            class="cursor-pointer"
            @click="viewDetails()"
          />
        </div>
      </template>
      <b-card-body>
        <slot name="content"> </slot>
      </b-card-body>
    </b-card>
    <Sidebar
      :show-sidebar="showSidebar"
      :title="title"
      :filters="filters"
      @closeSidebar="showSidebar = false"
      @reload="updateCard($event), (showSidebar = false)"
    />
  </div>
</template>
<script>
import Sidebar from "@/views/crm/views/dashboard/components/SidebarCrm.vue";
import moment from "moment";
export default {
  name: "CardDashboardCrm",
  components: {
    Sidebar,
  },
  props: {
    title: {
      type: String,
      default: "Card Title",
    },
    icon: {
      type: String,
      default: "ActivityIcon",
    },
    filters: {
      type: Array,
      default: () => [],
    },
    showDetails: {
      type: Boolean,
      default: true,
      required: false,
    },
    date: {
      type: [Date, String],
      default: null,
      required: false,
    },
    isActive: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      showSidebar: false,
    };
  },
  computed: {
    checkToday() {
      if (this.date) {
        return moment(this.date).format("MMMM DD, YYYY");
      }
      return moment().format("MMMM DD, YYYY");
    },
  },
  async created() {},
  mounted() {},
  methods: {
    async viewDetails() {
      this.$emit("details", this.filters);
    },
    updateCard(filters) {
      this.$emit("reload", filters);
    },
  },
};
</script>
<style lang="scss">
.card-dashboard-crm {
  .card-dashboard {
    box-shadow: none !important;
    border: 2px solid #ebebebcb;
    height: 100%;
    margin: 0;
    .card-header {
      background-color: #ebebebcb;
      padding: 0.8rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .card-header-title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.4rem;
        font-weight: bold;
        gap: 0.5rem;
      }
      .card-header-actions {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }
    .card-body {
      padding: 0.8rem;
      height: 100%;
    }
  }
  .card-dashboard--active {
    border: 2px solid #0090e7;
    .card-header {
      background-color: #0090e7;
      color: white;
    }
  }
}

.dark-layout {
  .card-dashboard-crm {
    .card-dashboard {
      border: 2px solid #303030;
      .card-header {
        background-color: #303030;
      }
    }
    .card-dashboard--active {
      border: 2px solid #0090e7;
      .card-header {
        background-color: #0090e7;
        color: white;
      }
    }
  }
}
</style>
