export default [
  {
    key: 'date',
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'Date',
    placeholder: 'Date',
    class: 'font-small-3',
    model: new Date(),
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 12,
  },
  {
    key:'programs',
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Program',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'name',
    cols: 12,
    clearable: true,
    visible: true,
    select_type: ['Sales'],
  },
  {
    key:'sellers',
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Seller',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'user_name',
    cols: 12,
    clearable: true,
    visible: true,
  },
];
