<template>
  <div class="pie-chart-answers-comparision">
    <b-card class="card-pie-chart-answers">
      <div class="header d-flex align-items-center">
        <b-badge variant="light-primary" class="badge-status">
          <feather-icon icon="PieChartIcon" size="25" class="status-icon" />
        </b-badge>
        <div class="title">
          Appointments <br> Comparison
          <div class="subtitle">({{ currentMonth }})</div>
        </div>
      </div>
      <div class="content-card">
        <e-charts ref="line" :options="chartOptions" autoresize />
      </div>
    </b-card>
  </div>
</template>
<script>
import DashboardService from "@/views/crm/views/dashboard/service/dashboard.service.js";
import moment from "moment";
import ECharts from "vue-echarts";
export default {
  name: "PieChartAnswersComparision",
  components: {
    ECharts,
  },
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    date: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      totalCallsUnanswered: 0,
      totalCallsAnswered: 0,
      totalAppointments: 0,
    };
  },
  computed: {
    currentMonth() {
      return moment(this.date).format("MMMM YYYY");
    },

    borderColor() {
      return this.isDarkSkin ? "#17171a" : "#ffffff";
    },

    chartOptions() {
      return {
        title: {
          show: false,
          text: "Answers Comparison",
          textStyle: {
            fontSize: 18,
            fontWeight: "bold",
          },
          left: "center",
          top: 10,
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          data: ["Answered", "Unanswered"],
          itemHeight: 30,
          itemWidth: 30,
          bottom: "20%",
          left: "center",
          color: ["#00DD31", "#FF8E00"],
          formatter: (name) => {
            let value = 0;
            if (name === "Unanswered") {
              value = this.totalCallsUnanswered;
            }
            if (name === "Answered") {
              value = this.totalCallsAnswered;
            }

            let percentage = ((value / this.totalAppointments) * 100).toFixed(2);
            percentage = isNaN(percentage) ? 0 : percentage;
            return [`${name}:`, `${value} (${percentage}%)`].join(" ");
          },
          textStyle: {
            color: this.isDarkSkin ? "#FFFFFF" : "#17171A",
            margin: 10,
            rich: {
              a: {
                height: 20,
                fontWeight: "bold",
                fontSize: 15,
              },
              b: {
                height: 10,
                fontSize: 12,
              },
            },
          },
        },
        series: [
          {
            name: "Appointments",
            type: "pie",
            radius: ["55%", "90%"],
            center: ["50%", "30%"],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 20,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: true,
              position: "center",
              fontWeight: "bold",
              formatter: `{a|${this.totalAppointments}}\n  Total \n Appointments`,
              color: this.isDarkSkin ? "#FFFFFF" : `#17171A`,
              rich: {
                a: {
                  fontSize: 40,
                  fontWeight: "bold",
                  color: this.isDarkSkin ? "#FFFFFF" : "#17171A",
                },
              },
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: this.totalCallsAnswered,
                name: "Answered",
                itemStyle: {
                  color: "#00DD31",
                  borderColor: this.borderColor,
                },
              },
              {
                value: this.totalCallsUnanswered,
                name: "Unanswered",
                itemStyle: {
                  color: "#FF8E00",
                  borderColor: this.borderColor,
                },
              },
            ],
          },
        ],
      };
    },
  },
  created() {
    this.getAppointmentsDashboardData();
  },
  mounted() {},
  methods: {
    async getAppointmentsDashboardData() {
      try {
        this.addPreloader();
        const params = {
          program_id: this.filters[1].model,
          seller_id: this.filters[2].model,
          date: this.filters[0].model,
          slug: "card-appointments-status",
        };
        const { data } = await DashboardService.getAppointmentsDashboardData(
          params
        );
        if (data.length === 0) return;
        this.totalCallsAnswered = parseInt(data[0].total_calls_answered);
        this.totalCallsUnanswered = parseInt(data[0].total_unanswered_calls);
        this.totalAppointments = parseInt(data[0].total_appointments);
      } catch (error) {
        this.showErrorSwal();
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>
<style lang="scss">
.pie-chart-answers-comparision {
  height: 100%;
  .card-pie-chart-answers {
    box-shadow: unset !important;
    height: 100%;
    .header {
      gap: 1rem;
      
      .badge-status {
        height: 2.5rem;
        width: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .status-icon {
          width: 2rem;
          height: 2rem;
        }
      }
      .title {
        font-weight: bold;
        font-size: 1.2rem;
        .subtitle {
          font-size: 1rem;
        }
      }
    }
    .content-card {
      max-height: 25rem;
    }
  }
}
</style>