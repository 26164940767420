<template>
  <div class="card-appointments-crm">
    <b-card
      body-class="card-appointments-body"
      class="card-appointments"
      @click="$emit('click')"
    >
      <div>
        <div class="actions">
          <div
            class="action-item"
            :class="{ 'action--active': isToday }"
            @click="$emit('changeData', 1)"
          >
            <strong class="number">{{ data.totalToday }}</strong>
            <div>Total appointments today</div>
          </div>
          <div
            class="action-item"
            :class="{ 'action--active': !isToday }"
            @click="$emit('changeData', 2)"
          >
            <strong class="number">{{ data.totalMonth }}</strong>
            <div>Total appointments his month</div>
          </div>
        </div>
        <div class="contents">
          <div class="list-item">
            <strong class="item-title">
              <div class="dot"></div>
              Answer
            </strong>
            <div class="sub-item">
              <strong @click="$emit('details', 1)">
                <feather-icon icon="CheckIcon" class="text-success" size="18" />
                {{ data.totalAnswered }}
              </strong>
              <strong @click="$emit('details', 2)">
                <feather-icon icon="XIcon" class="text-danger" size="18" />
                {{ data.totalUnanswered }}
              </strong>
            </div>
          </div>
          <div class="list-item">
            <strong class="item-title">
              <div class="dot"></div>
              Sales
            </strong>
            <div class="sub-item">
              <strong @click="$emit('details', 3)">
                <feather-icon icon="CheckIcon" class="text-success" size="18" />
                {{ data.totalSalesMade }}
              </strong>
              <strong @click="$emit('details', 4)">
                <feather-icon icon="XIcon" class="text-danger" size="18" />
                {{ data.totalSalesPending }}
              </strong>
            </div>
          </div>
        </div>
      </div>
      <div class="show-details-container text-center">
        <b-button
          v-b-tooltip.hover.bottom="`View Details in Appointments`"
          variant="primary"
          class="show-details-button"
          @click="$emit('details', 0)"
        >
          Show details
        </b-button>
      </div>
    </b-card>
  </div>
</template>
<script>
export default {
  name: "CardAppointmentsCrm",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    isToday: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
.card-appointments-crm {
  height: 100%;
  .card-appointments {
    box-shadow: 0px 1px 4px 0px rgb(57, 67, 76, 0.24) !important;
    padding: 1rem;
    margin: 0;
    height: 100%;
    cursor: pointer;
    .actions {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      .action-item {
        padding: 1rem;
        text-align: center;
        background: #f4f4f4;
        color: #999999;
        font-size: 1rem;
        border-radius: 0.4rem;
        width: 50%;
        strong {
          font-size: 2rem;
          font-weight: bold;
        }
        &:hover {
          color: unset;
          background: #edf3ff;
          cursor: pointer;
        }
      }
      .action--active {
        color: unset;
        background: #edf3ff;
      }
    }
    .contents {
      margin-top: 0.6rem;
      padding: 1rem 0.5rem 0 0.5rem;
      .list-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        border-bottom: 1px solid #efefef;
        font-size: 1.1rem;
        .sub-item {
          display: flex;
          align-items: center;
          gap: 2rem;
        }
        .dot {
          width: 0.3rem;
          height: 0.3rem;
          background: #5e5858;
          border-radius: 100%;
          margin-right: 0.5rem;
        }
        .item-title {
          display: flex;
          align-items: center;
        }
      }
    }
    .show-details-container {
      margin-top: 1rem;
      .show-details-button {
        padding: 5px 10px;
      }
    }
    .card-appointments-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
.dark-layout {
  .card-appointments-crm {
    .card-appointments {
      background: #1c1c1e;
      box-shadow: 0px 1px 8px 1px rgb(0 0 0 / 24%) !important;
      .action-item {
        background: #2d2d2d;
        color: #fff;
        &:hover {
          color: #fff;
          background: #0090e7;
        }
      }
      .action--active {
        color: #fff;
        background: #0090e7;
      }
      .list-item {
        border-bottom: 1px solid #2e2e2e;
        .dot {
          background: #fff;
        }
      }
    }
  }
}
</style>