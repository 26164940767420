export default [
  {
    key: 'client_name',
    sortable: false,
    label: 'Client',
    visible: true,
    thStyle: 'background: #0090E7; color: white; border-radius: 15px 0 0 0;',
  },
  {
    key: 'add_or_change_service',
    sortable: false,
    label: 'Type',
    visible: true,
    thStyle: 'background: #0090E7; color: white;',
  },
  {
    key: 'destination_program_id',
    sortable: false,
    label: 'Service',
    visible: true,
    thStyle: 'background: #0090E7; color: white;',
  },
  {
    key: 'seller_name',
    sortable: false,
    label: 'Seller',
    visible: true,
    thStyle: 'background: #0090E7; color: white;',
  },
  {
    key: 'fee',
    sortable: false,
    label: 'Fee',
    visible: true,
    thStyle: 'background: #0090E7; color: white;',
  },
  {
    key: 'ip',
    sortable: false,
    label: 'Initial Payment',
    visible: true,
    thStyle: 'background: #0090E7; color: white;',
  },
  {
    key: 'last_call_at',
    sortable: false,
    label: 'Last Call At',
    visible: true,
    thStyle: 'background: #0090E7; color: white;',
  },
  {
    key: 'approved_at',
    sortable: false,
    label: 'Approved at',
    visible: true,
    thStyle: 'background: #0090E7; color: white;',
  },
  {
    key: 'created_at',
    sortable: false,
    label: 'Created at',
    visible: true,
    thStyle: 'background: #0090E7; color: white; border-radius: 0 15px 0 0;',
  },
];
