export default [
  {
    key:'date',
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'Date',
    placeholder: 'Date',
    class: 'font-small-3',
    model: new Date(),
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 12,
    select_type: ['Leads'],
  }, 
  {
    key:'originModule',
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Origin',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'value',
    cols: 12,
    clearable: true,
    visible: true,
    select_type: ['Leads'],
  },
];
