<template>
  <b-card class="e-chard-card-container">
    <div class="d-flex justify-content-between">
      <div class="d-flex" style="margin-bottom: 20px">
        <div class="icon-container">
          <feather-icon icon="BarChartIcon" size="24" style="color: #1654cc" />
        </div>
        <div class="pl-2 align-self-center">
          <div>
            <span style="font-weight: 700; font-size: 15px">
              {{
                `${currentSelectionName} Comparison Between Months (${currentSalesDateYear})`
              }}
            </span>
          </div>
        </div>
      </div>
      <div style="position: relative">
        <div
          style="
            padding-top: 5px;
            width: 180px;
            position: absolute;
            right: 15px;
          "
          :class="!editStatusEdition ? '' : 'd-none'"
        >
          <label
            :for="'bar-selector'"
            class="cursor-pointer"
            @click="enableStatusEdition()"
          >
            <b-badge
              pill
              :variant="statusVariant(currentOptionSelection)"
              class="cursor-pointer unselectable-text"
              style="font-size: 18px"
            >
              <span style="padding-left: 5px; padding-right: 5px">
                {{ currentSelectedOptionName }}
                <feather-icon
                  class="text-white"
                  icon="ChevronDownIcon"
                  style="margin: 3px 0 0 0"
                  size="16"
                />
              </span>
            </b-badge>
          </label>
        </div>
        <v-select
          v-if="editStatusEdition"
          v-model="currentOptionSelection"
          :input-id="'bar-selector'"
          :reduce="(red) => red.id"
          class="text-center cursor-pointer select-size-sm"
          :dir="'rtl'"
          :options="optionsSelection"
          label="type"
          style="width: 220px; padding-left: 5px; padding-right: 5px"
          :clearable="false"
          :append-to-body="true"
          @input="closeGraphicType()"
          @close="closeGraphicType()"
        >
          <template #option="{ id, type }">
            <b-badge
              pill
              class="text-center"
              :variant="statusVariant(id)"
              style="font-size: 16px"
            >
              <span style="padding-left: 5px; padding-right: 5px">
                {{ type }}
              </span>
            </b-badge>
          </template>
        </v-select>
      </div>
    </div>
    <e-charts
      v-if="currentSelectionIsSales"
      ref="line"
      :options="salesChartOptions"
      autoresize
    />
    <e-charts
      v-if="currentSelectionIsFee"
      ref="line"
      :options="feeChartOptions"
      autoresize
    />
  </b-card>
</template>
<script>
import DashboardService from "@/views/crm/views/dashboard/service/dashboard.service";
import ECharts from "vue-echarts";
import moment from "moment";

export default {
  name: "BarChartAppointmentsCrm",
  components: {
    ECharts,
  },
  props: {
    programId: {
      type: Number,
      required: false,
      default: null,
    },
    sellerId: {
      type: Number,
      required: false,
      default: null,
    },
    date: {
      type: String,
      required: false,
      default: null,
    },
    currentGraphicOptionSelection: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentOptionSelection: this.currentGraphicOptionSelection,
      editStatusEdition: false,
      optionsSelection: [
        {
          id: 1,
          type: "Sales",
        },
        {
          id: 2,
          type: "Fee",
        },
      ],
      leadsData: {
        xAxisData: [],
        totalLeads: 0,
        totalLeadsAdd: 0,
        totalLeadsChage: 0,
      },
      eCharts: {
        option: {},
      },
      yearlyPendingData: [],
      yearlyDoneData: [],
    };
  },
  computed: {
    /* Computed properties for Fee Chart */
    yearlyPendingFee() {
      const yearlyFee = this.yearlyPendingData.map(
        (month) => month.indicators.monthly_sales_fee_amount
      );
      return yearlyFee;
    },
    totalYearlyPendingFee() {
      const sum = this.yearlyPendingFee.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      return sum;
    },
    yearlyDoneFee() {
      const yearlyFee = this.yearlyDoneData.map(
        (month) => month.indicators.monthly_sales_fee_amount
      );
      return yearlyFee;
    },
    totalYearlyDoneFee() {
      const sum = this.yearlyDoneFee.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      return sum;
    },
    totalYearlyFee() {
      return this.totalYearlyDoneFee + this.totalYearlyPendingFee;
    },
    feeChartOptions() {
      return {
        title: {
          text: "Yearly Fee",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            let valueDone = "";
            let valuePending = "";
            let output = "";
            let valueMonth;
            if (params[0]) {
              const totalMonthly = params[0].value + params[1].value;
              valueDone = params[0].value;
              valuePending = params[1].value;
              valueMonth = params[0].axisValue;
              valueDone = this.setCurrencyZero(valueDone);
              valuePending = this.setCurrencyZero(valuePending);
              const percentageDone =
                totalMonthly === 0
                  ? 0
                  : ((params[0].value / totalMonthly) * 100).toFixed(2);
              const percentagePending =
                totalMonthly === 0
                  ? 0
                  : ((params[1].value / totalMonthly) * 100).toFixed(2);
              output = `<div style="padding-left: 0 !important;">
        <span style="color: padding-left: 0 !important; margin-left: 0 !important;">${valueMonth}</span>
        <div>
          <div style="padding-left: 0 !important; margin-left: 0 !important;"><span style="color: #00DD31; font-size: 20px;">●</span> Done: $${valueDone} (${percentageDone}%)</div>
          <div style="padding-left: 0 !important; margin-left: 0 !important;"><span style="color: #FF8E00; font-size: 20px;">●</span> Pending: $${valuePending} (${percentagePending}%)</div>
        </div>
      </div>`;
            }
            return output;
          },
        },
        legend: {
          data: ["Done", "Pending"],
          color: ["#00DD31", "#FF8E00"],
          itemHeight: 30,
          itemWidth: 30,

          textStyle: {
            color: this.isDarkSkin ? "#FFFFFF" : "#17171A",
            margin: 10,
            rich: {
              a: {
                height: 20,
                fontWeight: "bold",
                fontSize: 15,
              },
              b: {
                height: 10,
                fontSize: 12,
              },
            },
          },
          formatter: (name) => {
            let value = 0;
            let percentage = 0;
            if (name === "Done") {
              value = this.setCurrencyZero(this.totalYearlyDoneFee);
              percentage = (
                (this.totalYearlyDoneFee / this.totalYearlyFee) *
                100
              ).toFixed(2);
            }
            if (name === "Pending") {
              value = this.setCurrencyZero(this.totalYearlyPendingFee);
              percentage = (
                (this.totalYearlyPendingFee / this.totalYearlyFee) *
                100
              ).toFixed(2);
            }
            return [
              `{b|${name}:}`,
              `{b|$${value}}`,
              `{b|(${percentage}%)}`,
            ].join(" ");
          },
        },

        xAxis: {
          type: "category",
          axisTick: { show: false },
          data: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          axisLabel: {
            color: this.isDarkSkin ? "#FFFFFF" : "#616161",
            margin: 10,
            fontSize: 14,
            fontWeight: "500",
          },
        },

        yAxis: {
          type: "value",
          minInterval: 1,
          axisLabel: {
            margin: 10,
            fontSize: 15,
            fontWeight: "500",
            color: this.isDarkSkin ? "#FFFFFF" : "#616161",
          },

          nameTextStyle: {
            color: this.isDarkSkin ? "#FFFFFF" : "#616161",
          },
          axisLine: {
            show: true,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: this.isDarkSkin ? "#FFFFFF" : "#616161",
              type: "dashed",
              width: 1.3,
              interval: 10,
            },
          },
        },

        series: [
          {
            name: "Done",
            type: "bar",
            barWidth: 14,
            barGap: 0,
            color: "#00DD31",
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                barBorderRadius: [5, 5, 0, 0],
              },
            },
            data: this.yearlyDoneFee,
          },
          {
            name: "Pending",
            type: "bar",
            barWidth: 14,
            barGap: 0,
            color: "#FF8E00",
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                barBorderRadius: [5, 5, 0, 0],
              },
            },
            data: this.yearlyPendingFee,
          },
        ],
      };
    },
    /* Computed properties for Sales Chart */
    yearlyPendingSales() {
      const yearlySales = this.yearlyPendingData.map(
        (month) => month.indicators.monthly_sales
      );
      return yearlySales;
    },
    totalYearlyPendingSales() {
      const sum = this.yearlyPendingSales.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      return sum;
    },
    yearlyDoneSales() {
      const yearlySales = this.yearlyDoneData.map(
        (month) => month.indicators.monthly_sales
      );
      return yearlySales;
    },
    totalYearlyDoneSales() {
      const sum = this.yearlyDoneSales.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      return sum;
    },
    totalYearlySales() {
      return this.totalYearlyPendingSales + this.totalYearlyDoneSales;
    },
    salesChartOptions() {
      return {
        title: {
          text: "Yearly Sales",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            let valueDone = "";
            let valuePending = "";
            let output = "";
            let valueMonth;
            if (params[0]) {
              const totalMonthly = params[0].value + params[1].value;
              valueDone = params[0].value;
              valuePending = params[1].value;
              valueMonth = params[0].axisValue;
              const percentageDone =
                totalMonthly === 0
                  ? 0
                  : ((params[0].value / totalMonthly) * 100).toFixed(2);
              const percentagePending =
                totalMonthly === 0
                  ? 0
                  : ((params[1].value / totalMonthly) * 100).toFixed(2);
              output = `<div style="padding-left: 0 !important;">
        <span style="color: padding-left: 0 !important; margin-left: 0 !important;">${valueMonth}</span>
        <div>
          <div style="padding-left: 0 !important; margin-left: 0 !important;"><span style="color: #00DD31; font-size: 20px;">●</span> Done: ${valueDone} (${percentageDone}%)</div>
          <div style="padding-left: 0 !important; margin-left: 0 !important;"><span style="color: #FF8E00; font-size: 20px;">●</span> Pending: ${valuePending} (${percentagePending}%)</div>
        </div>
      </div>`;
            }
            return output;
          },
        },

        legend: {
          data: ["Done", "Pending"],
          color: ["#00DD31", "#FF8E00"],
          itemHeight: 30,
          itemWidth: 30,

          textStyle: {
            color: this.isDarkSkin ? "#FFFFFF" : "#17171A",
            margin: 10,
            rich: {
              a: {
                height: 20,
                fontWeight: "bold",
                fontSize: 15,
              },
              b: {
                height: 10,
                fontSize: 12,
              },
            },
          },
          formatter: (name) => {
            let value = 0;
            let percentage = 0;
            if (name === "Done") {
              value = this.totalYearlyDoneSales;
              percentage = (
                (this.totalYearlyDoneSales / this.totalYearlySales) *
                100
              ).toFixed(2);
            }
            if (name === "Pending") {
              value = this.totalYearlyPendingSales;
              percentage = (
                (this.totalYearlyPendingSales / this.totalYearlySales) *
                100
              ).toFixed(2);
            }
            return [
              `{b|${name}:}`,
              `{b|${value}}`,
              `{b|(${percentage}%)}`,
            ].join(" ");
          },
        },

        xAxis: {
          type: "category",
          axisTick: { show: false },
          data: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          axisLabel: {
            color: this.isDarkSkin ? "#FFFFFF" : "#616161",
            margin: 10,
            fontSize: 14,
            fontWeight: "500",
          },
        },

        yAxis: {
          type: "value",
          minInterval: 1,
          axisLabel: {
            margin: 10,
            fontSize: 15,
            fontWeight: "500",
            color: this.isDarkSkin ? "#FFFFFF" : "#616161",
          },

          nameTextStyle: {
            color: this.isDarkSkin ? "#FFFFFF" : "#616161",
          },
          axisLine: {
            show: true,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: this.isDarkSkin ? "#FFFFFF" : "#616161",
              type: "dashed",
              width: 1.3,
              interval: 10,
            },
          },
        },

        series: [
          {
            name: "Done",
            type: "bar",
            barWidth: 14,
            barGap: 0,
            color: "#00DD31",
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                barBorderRadius: [5, 5, 0, 0],
              },
            },
            data: this.yearlyDoneSales,
          },
          {
            name: "Pending",
            type: "bar",
            barWidth: 14,
            barGap: 0,
            color: "#FF8E00",
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              normal: {
                barBorderRadius: [5, 5, 0, 0],
              },
            },
            data: this.yearlyPendingSales,
          },
        ],
      };
    },
    currentSalesDateYear() {
      const currentDate = moment(this.date, "YYYY-MM-DD");
      const yearNumber = currentDate.format("YYYY");
      return yearNumber;
    },
    currentValueIsMoney() {
      return this.currentOptionSelection !== 1;
    },
    currentSelectionIsSales() {
      return this.currentOptionSelection === 1;
    },
    currentSelectionIsFee() {
      return this.currentOptionSelection === 2;
    },
    currentSelectionName() {
      switch (this.currentOptionSelection) {
        case 1:
          return "Sales";
        case 2:
          return "Fee";
        default:
          return "";
      }
    },
    currentSelectedOptionName() {
      return this.optionsSelection.find(
        (option) => option.id === this.currentOptionSelection
      ).type;
    },
  },
  watch: {
    currentOptionSelection(newVal) {
      this.$emit("keepSelectionSync", newVal);
    },
  },
  beforeDestroy() {
    this.$emit("keepSelectionSync", this.currentOptionSelection);
  },
  async created() {
    this.yearlyPendingData = await this.getYearlyData("PENDING");
    this.yearlyDoneData = await this.getYearlyData("DONE");
  },
  methods: {
    async getYearlyData(salesStatus) {
      try {
        const params = {
          program_id: this.programId,
          status_sale: salesStatus,
          seller_id: this.sellerId,
          date: this.date,
        };
        const { data } = await DashboardService.getCrmDashboardYearlySales(
          params
        );
        return data;
      } catch (error) {
        this.showErrorSwal();
      } finally {
        this.removePreloader();
      }
    },
    capitalizeFirstLetter(inputString) {
      if (typeof inputString !== "string") {
        throw new Error("Input must be a string");
      }

      if (inputString.length === 0) {
        return inputString;
      }

      const firstLetter = inputString.charAt(0).toUpperCase();
      const restOfString = inputString.slice(1).toLowerCase();

      return firstLetter + restOfString;
    },
    setCurrencyZero(number) {
      function formatPrice(value) {
        const val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      if (number) {
        return formatPrice(number);
      }
      return "0.00";
    },
    async enableStatusEdition() {
      this.editStatusEdition = true;
    },
    statusVariant(status) {
      switch (status) {
        case 1:
          return "primary";
        case 2:
          return "info";
        default:
          return "warning";
      }
    },
    closeGraphicType() {
      this.editStatusEdition = false;
    },
  },
};
</script>
   <style>
.echarts {
  width: 100% !important;
  margin: auto;
}
.e-chard-card-container {
  padding: 0 10px 0 10px;
  height: 100%;
  width: 100%;
  box-shadow: unset !important;
}
</style>
