<template>
  <div class="bar-chart-appointments-crm">
    <b-card class="bar-chart-appointments">
      <div class="header d-flex align-items-center">
        <b-badge variant="light-primary" class="badge-status">
          <feather-icon icon="BarChart2Icon" size="25" class="status-icon" />
        </b-badge>
        <div class="title">Comparison of result ({{ currentYear }})</div>
      </div>
      <div class="content">
        <e-charts ref="line" :options="eCharts.option" autoresize />
      </div>
    </b-card>
  </div>
</template>
  <script>
import DashboardService from "@/views/crm/views/dashboard/service/dashboard.service.js";
import ECharts from "vue-echarts";
import moment from "moment";
export default {
  name: "BarChartAppointmentsCrm",
  components: {
    ECharts,
  },
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
    date: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      appointmenstsData: {
        xAxisData: [],
        totalAnswered: 0,
        totalSalesMade: 0,
      },
      eCharts: {
        option: {},
      },
    };
  },
  computed: {
    currentYear() {
      return moment(this.date).format("YYYY");
    },
  },
  async created() {
    this.eCharts.option = {
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },

      legend: {
        data: ["Answer", "Sale"],
        color: ["#3F7AFA", "#00DD31"],
        itemHeight: 30,
        itemWidth: 30,

        textStyle: {
          color: this.isDarkSkin ? "#FFFFFF" : "#17171A",
          margin: 10,
          rich: {
            a: {
              height: 20,
              fontWeight: "bold",
              fontSize: 15,
            },
            b: {
              height: 10,
              fontSize: 12,
            },
          },
        },
        formatter: (name) => {
          let value = 0;
          if (name == "Answer") {
            value = this.appointmenstsData.totalAnswered;
          }
          if (name == "Sale") {
            value = this.appointmenstsData.totalSalesMade;
          }
          return ["{b|" + name + ":}", "{b|" + value + "}"].join(" ");
        },
      },

      xAxis: {
        type: "category",
        axisTick: { show: false },
        data: [],
        axisLabel: {
          color: this.isDarkSkin ? "#FFFFFF" : "#616161",
          margin: 10,
          fontSize: 14,
          fontWeight: "500",
        },
      },

      yAxis: {
        type: "value",
        minInterval: 1,
        axisLabel: {
          margin: 10,
          fontSize: 15,
          fontWeight: "500",
          color: this.isDarkSkin ? "#FFFFFF" : "#616161",
        },

        nameTextStyle: {
          color: this.isDarkSkin ? "#FFFFFF" : "#616161",
        },
        axisLine: {
          show: true,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: this.isDarkSkin ? "#FFFFFF" : "#616161",
            type: "dashed",
            width: 1.3,
            interval: 10,
          },
        },
      },

      series: [
        {
          name: "Answer",
          type: "bar",
          barGap: 0,
          color: "#3F7AFA",
          label: {
            show: true,
            position: "top",
            distance: 12,
            align: "center",
            verticalAlign: "middle",
            rotate: 1,
            formatter: "{c}",
            fontSize: 12,
          },
          emphasis: {
            focus: "series",
          },
          itemStyle: {
            normal: {
              barBorderRadius: [10, 10, 0, 0],
            },
          },
          data: [],
        },
        {
          name: "Sale",
          type: "bar",
          barGap: 0,
          color: "#00DD31",
          label: {
            show: true,
            position: "top",
            distance: 12,
            align: "center",
            verticalAlign: "middle",
            rotate: 1,
            formatter: "{c}",
            fontSize: 12,
          },
          emphasis: {
            focus: "series",
          },
          itemStyle: {
            normal: {
              barBorderRadius: [10, 10, 0, 0],
            },
          },
          data: [],
        },
      ],
    };
    await this.getAppointmentsDashboardData();
  },
  mounted() {},
  methods: {
    async getAppointmentsDashboardData() {
      try {
        this.addPreloader();
        const params = {
          program_id: this.filters[1].model,
          seller_id: this.filters[2].model,
          date: this.filters[0].model,
          slug: "bar-chart-appointments",
        };
        const { data } = await DashboardService.getAppointmentsDashboardData(
          params
        );
        await this.processDataForChart(data);
      } catch (error) {
        this.showErrorSwal();
      } finally {
        this.removePreloader();
      }
    },

    async processDataForChart(data) {
      this.appointmenstsData.xAxisData = [
        ...data.map((item) => item.month_name),
      ];
      this.eCharts.option.xAxis.data = this.appointmenstsData.xAxisData;

      this.processTotalDataSeries(data);
      this.processTotalDataLegend(data);
    },

    processTotalDataSeries(data) {
      this.eCharts.option.series[0].data = [
        ...data.map((item) => item.total_calls_answered),
      ];

      this.eCharts.option.series[1].data = [
        ...data.map((item) => item.total_sales_made),
      ];
    },

    processTotalDataLegend(data) {
      this.appointmenstsData.totalAnswered = data.reduce(
        (acc, item) => acc + parseInt(item.total_calls_answered),
        0
      );

      this.appointmenstsData.totalSalesMade = data.reduce(
        (acc, item) => acc + parseInt(item.total_sales_made),
        0
      );
    },
  },
  watch: {
    isDarkSkin: {
      handler: function (val, oldVal) {
        this.eCharts.option.legend.textStyle.color = val
          ? "#FFFFFF"
          : "#17171A";
        this.eCharts.option.xAxis.axisLabel.color = val ? "#FFFFFF" : "#616161";
        this.eCharts.option.yAxis.axisLabel.color = val ? "#FFFFFF" : "#616161";
        this.eCharts.option.yAxis.splitLine.lineStyle.color = val
          ? "#FFFFFF"
          : "#616161";
      },
      deep: true,
    },
  },
};
</script>
 <style lang="scss">
.bar-chart-appointments-crm {
  .echarts {
    width: 100% !important;
    margin: auto;
  }
  .bar-chart-appointments {
    box-shadow: unset !important;
    margin: 0;

    .header {
      gap: 0.5rem;
      .badge-status {
        height: 2.5rem;
        width: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .status-icon {
          width: 2rem;
          height: 2rem;
        }
      }
      .title {
        font-weight: bold;
        font-size: 1.2rem;
      }
    }

    .content {
      margin: 0 !important;
    }
  }
}
</style>